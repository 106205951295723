import {
  Box,
  Flex,
  VStack,
  Text,
  Image,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { Header } from "../common/Header";
import { SearchButton } from "../common/SearchButton";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../../constants";
import { useQuery } from "@tanstack/react-query";
import { useAuthRef } from "../../providers/use-auth";
import { DateRateProvider } from "../../providers/use-daterate";
import { createTheme, Rating, ThemeProvider } from "@mui/material";

const theme = createTheme({});

function DateRankingRow({
  name,
  image,
  score,
  disabled,
  profileId,
  rank,
  stars,
}: {
  name: string;
  image: string;
  score: string;
  disabled?: boolean;
  profileId: string;
  rank: number;
  stars: number;
}) {
  const navigate = useNavigate();
  const scoreNum = parseFloat(score);
  console.log(scoreNum);

  const getScoreColor = (score: number) => {
    if (isNaN(score)) return "gray.500";
    if (score >= 8) return "green.500";
    if (score >= 6) return "yellow.500";
    if (score >= 4) return "orange.500";
    return "red.500";
  };

  return (
    <Flex
      bg="white"
      boxShadow="sm"
      borderRadius="md"
      p={3}
      width="100%"
      alignItems="center"
      opacity={disabled ? 0.5 : 1}
      transition="all 0.3s ease"
      _hover={
        !disabled ? { boxShadow: "md", transform: "translateY(-2px)" } : {}
      }
      cursor={disabled ? "not-allowed" : "pointer"}
      onClick={() => {
        if (!disabled) {
          navigate(`/profile/${profileId}`, {
            state: { backPath: "/rankings" },
          });
        }
      }}
    >
      <Flex alignItems="center" width="100%">
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="bold"
          color="gray.700"
          mr={3}
          minWidth="24px"
          textAlign="center"
        >
          {rank}
        </Text>
        <Image
          src={image}
          alt={name}
          boxSize={{ base: "50px", md: "60px" }}
          borderRadius="full"
          objectFit="cover"
          mr={3}
        />
        <Flex flex={1} direction="column" justifyContent="center">
          <Text
            fontWeight="semibold"
            fontSize={{ base: "md", md: "lg" }}
            isTruncated
          >
            {name}
          </Text>
          <ThemeProvider theme={theme}>
            <Rating name="simple-controlled" value={stars} size="small" />
          </ThemeProvider>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          borderWidth="2px"
          borderColor={getScoreColor(scoreNum)}
          width={{ base: "36px", md: "44px" }}
          height={{ base: "36px", md: "44px" }}
          ml={2}
        >
          <Text
            fontWeight="bold"
            fontSize={{ base: "sm", md: "md" }}
            color={getScoreColor(scoreNum)}
          >
            {score}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

type RankingData = {
  rating: number | null;
  normalizedRating: number | null;
  metadata: { imgUrls: string[]; profileId: string; name: string };
  stars: {
    profileId: string;
    avgUserRating: number | null;
  };
};

export function DateRankingsInner() {
  const navigate = useNavigate();
  const { jwt } = useAuthRef();

  const { data: rankingData, isPending } = useQuery({
    queryKey: ["rankingData", jwt],
    queryFn: async (): Promise<RankingData[]> => {
      if (!jwt) {
        return [];
      }
      const response = await fetch(`${API_URL}/v1/user/ranked-accounts`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch ranking data");
      }
      return (await response.json()).rankedAccounts;
    },
  });

  const exampleDates = [
    {
      name: "Alex",
      image:
        "https://daterate.s3.amazonaws.com/9919f5a7-0ecc-4228-b9f2-2dcef5567556",
      score: "9.9",
      profileId: "123",
      stars: 5,
    },
    {
      name: "Kelvin",
      image:
        "https://daterate.s3.amazonaws.com/1c4aba2e-06c2-4e53-a2b2-89eb0e5d9bb8",
      score: "5.6",
      profileId: "123",
      stars: 3.5,
    },
    {
      name: "Gabe",
      image:
        "https://daterate.s3.amazonaws.com/d1beb632-d144-499b-adb0-c4457894ad0b",
      score: "3.2",
      profileId: "123",
      stars: 2,
    },
  ];

  const pastDates: {
    name: string;
    image: string;
    score: string;
    profileId: string;
    stars: number;
  }[] =
    rankingData?.map((ranking) => ({
      name:
        ranking.metadata.name.charAt(0).toUpperCase() +
        ranking.metadata.name.slice(1),
      image: ranking.metadata.imgUrls[0],
      score: ranking.normalizedRating?.toFixed(1) ?? "?",
      profileId: ranking.metadata.profileId,
      stars: ranking.stars.avgUserRating ?? 0,
    })) ?? [];

  if (isPending) {
    return (
      <Flex
        height={"100dvh"}
        width={"100vw"}
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        padding={5}
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      height={"100dvh"}
      width={"100vw"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      padding={5}
      bgGradient="linear(to-b, white, pink.100)"
    >
      <Header leftText="Your Dates" />
      <SearchButton onClick={() => navigate("/")} />
      <Box
        width="100%"
        maxWidth={{ base: "100%", md: "800px" }}
        mx="auto"
        height="calc(100vh - 150px)"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(0,0,0,0.2)",
            borderRadius: "24px",
          },
        }}
      >
        <VStack spacing={1} width="100%">
          {pastDates.length > 0 ? (
            pastDates.map((date, index) => (
              <Box
                key={date.profileId}
                width="100%"
                _hover={{ bg: "gray.50" }}
                borderRadius={"md"}
                transition="background-color 0.2s"
              >
                <DateRankingRow {...date} rank={index + 1} />
              </Box>
            ))
          ) : (
            <Flex flexDirection="column" width="100%" height="100%" py={8}>
              {exampleDates.map((date, index) => (
                <Box key={index} width="100%" opacity={0.5} filter="blur(2px)">
                  <DateRankingRow {...date} disabled rank={index + 1} />
                </Box>
              ))}
              <Flex
                flex={1}
                height="100%"
                justifyContent="center"
                alignItems="center"
                px={4}
                textAlign="center"
              >
                <VStack spacing={6}>
                  <Text
                    fontSize={{ base: "xl", md: "2xl" }}
                    fontWeight="bold"
                    color="pink.500"
                  >
                    Your Date Lineup is Empty!
                  </Text>
                  <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
                    Start building your lineup by reviewing your first date.
                  </Text>
                  <Button
                    colorScheme="pink"
                    size="lg"
                    leftIcon={<FontAwesomeIcon icon={faSearch} />}
                    onClick={() => navigate("/")}
                    _hover={{ transform: "translateY(-2px)" }}
                    transition="all 0.2s"
                    boxShadow="md"
                  >
                    Review a Date
                  </Button>
                </VStack>
              </Flex>
            </Flex>
          )}
        </VStack>
      </Box>
    </Flex>
  );
}

export function DateRankings() {
  return (
    <DateRateProvider>
      <DateRankingsInner />
    </DateRateProvider>
  );
}
