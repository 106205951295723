import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { REVIEW_CATEGORIES } from "../../data/review";
import { Collapse, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createTheme, Rating, ThemeProvider } from "@mui/material";
import { QuestionIcon } from "@chakra-ui/icons";

const theme = createTheme();

export function RatingRow({
  category,
  rating,
  icon,
  iconColor,
}: {
  category: string;
  rating?: number;
  icon: IconDefinition;
  iconColor: string;
}) {
  const [key, setKey] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const option = REVIEW_CATEGORIES[category]?.options?.find(
    (option) => option.stars === Math.round(rating ?? 0)
  );

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [rating]);

  if (!rating) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      borderWidth={1}
      borderRadius="md"
      p={4}
      cursor="pointer"
      boxShadow="sm"
      opacity={rating !== undefined ? 1 : 0.5}
      w="100%"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
      }}
      _active={{
        bg: "gray.100",
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        width="100%"
      >
        <Flex alignItems="center" width="100%" mb={2}>
          <FontAwesomeIcon icon={icon} color={iconColor} />
          <Text ml={2} color="gray.600">
            {REVIEW_CATEGORIES[category]?.prettyName}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex flex={1} alignItems="center">
            <Flex flexDirection="column" width="100%">
              <Text
                fontWeight="bold"
                fontSize={["md", "lg"]}
                noOfLines={1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {option?.title}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems="center" flexShrink={0}>
            <ThemeProvider theme={theme}>
              <Rating
                key={key}
                name={`option-${category}`}
                value={rating}
                readOnly
                size="small"
              />
            </ThemeProvider>

            <Flex alignItems="center" height="" position="relative">
              <Flex
                position="absolute"
                width="calc(100% + 20px)"
                height="calc(100% + 30px)"
              />
              <Divider
                orientation="vertical"
                height="20px"
                mx={2}
                borderColor="gray.400"
              />
              <Icon
                as={QuestionIcon}
                aria-label="More information"
                color="gray.500"
                pointerEvents="none"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Collapse in={expanded} animateOpacity>
        <Text fontSize="sm" color="gray.600" mt={2}>
          {option?.description}
        </Text>
      </Collapse>
    </Flex>
  );
}
