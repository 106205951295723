import { Flex } from "@chakra-ui/react";
import { MultiSelect } from "./MultiSelect";
import { useReviewRef } from "../../providers/use-review";
import { FLAG_CATEGORIES } from "../../data/review";

export function Topics() {
  const { reviewData, setReviewData } = useReviewRef();

  const topics = FLAG_CATEGORIES.TOPICS.options.map((topic) => ({
    key: topic.systemName,
    value: topic.prettyName,
    icon: topic.emoji!,
  }));

  const handleTopicsChange = (selected: string[]) => {
    setReviewData({
      ...reviewData,
      flags: {
        ...reviewData.flags,
        TOPICS: selected,
      },
    });
  };

  return (
    <Flex w="100%">
      <MultiSelect
        selected={reviewData.flags.TOPICS ?? []}
        options={topics}
        onChange={handleTopicsChange}
      />
    </Flex>
  );
}
