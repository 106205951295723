import { useState } from "react";
import { TILE_CATEGORIES } from "../../data/review";
import { Flex, Text, Image, Collapse } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownOption, DropdownSelection } from "../../types/review";

function GraphicComponent({
  option,
  size,
}: {
  option?: DropdownOption;
  size: string;
}) {
  const fontSize = size === "sm" ? "1.5rem" : "2.5rem";
  const imageSize = size === "sm" ? "1.5rem" : "2.5rem";

  if (!option) {
    return <Flex></Flex>;
  }
  if (option.emoji) {
    return (
      <Flex>
        <Text fontSize={fontSize}>{option.emoji}</Text>
      </Flex>
    );
  }
  if (option.imagePath) {
    return (
      <Flex>
        <Image
          src={option.imagePath}
          width={imageSize}
          height={imageSize}
          objectFit="contain"
        />
      </Flex>
    );
  }
  if (option.icon) {
    return (
      <Flex>
        <FontAwesomeIcon icon={option.icon} fontSize={fontSize} />
      </Flex>
    );
  }
  return <Flex></Flex>;
}

export function DropdownRow({
  category,
  selections,
}: {
  category: string;
  selections?: DropdownSelection[];
}) {
  const [expanded, setExpanded] = useState(false);

  const mostCommonSelection = selections?.reduce(
    (mostCommon, current) => {
      return mostCommon.cnt > current.cnt ? mostCommon : current;
    },
    { cnt: 0, name: "", value: "" }
  );

  const option = TILE_CATEGORIES[category]?.options?.find(
    (option) => option.systemName === mostCommonSelection?.name
  );

  const totalResponses =
    selections?.reduce(
      // @ts-ignore
      (sum, current) => parseInt(sum) + parseInt(current.cnt),
      0
    ) || 0;

  if (!option) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      borderWidth={1}
      borderRadius="md"
      p={4}
      cursor="pointer"
      boxShadow="sm"
      opacity={mostCommonSelection?.name !== undefined ? 1 : 0.5}
      w="100%"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
      }}
      _active={{
        bg: "gray.100",
      }}
    >
      <Flex
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDirection="column" flex={1}>
          <Text color="gray.600" fontSize="sm" mb={1}>
            {TILE_CATEGORIES[category]?.phrase}
          </Text>
          <Text
            fontWeight="bold"
            fontSize={["md", "lg"]}
            noOfLines={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {option?.prettyName || "Not specified"}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <GraphicComponent option={option} size="md" />
        </Flex>
      </Flex>
      <Collapse in={expanded} animateOpacity>
        <Flex flexDirection="column" mt={4} borderTopWidth={1} pt={2}>
          {selections?.map((selection) => {
            const selectionOption = TILE_CATEGORIES[category]?.options?.find(
              (opt) => opt.systemName === selection.name
            );
            const percentage = ((selection.cnt / totalResponses) * 100).toFixed(
              1
            );
            return (
              <Flex
                key={selection.name}
                justifyContent="space-between"
                alignItems="center"
                py={2}
                borderBottomColor="gray.200"
              >
                <Flex alignItems="center">
                  <GraphicComponent option={selectionOption} size="sm" />
                  <Text ml={2} fontSize="sm">
                    {selectionOption?.prettyName || selection.name}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                  {percentage}% ({selection.cnt})
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Collapse>
    </Flex>
  );
}
