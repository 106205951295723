import { Flex } from "@chakra-ui/react";
import { ReviewStarOptions } from "../review/ReviewStarOptions";
import { REVIEW_CATEGORIES } from "../../data/review";
import { useReviewRef } from "../../providers/use-review";

export function Catfish({ onClose }: { onClose: () => void }) {
  const { reviewData, setReviewData } = useReviewRef();

  const handleCatfishChange = (rating: number) => {
    onClose();
    setReviewData({
      ...reviewData,
      ratings: {
        ...reviewData.ratings,
        catfish: rating,
      },
    });
  };

  return (
    <Flex w="100%">
      <ReviewStarOptions
        onChange={handleCatfishChange}
        category={REVIEW_CATEGORIES.catfish}
        showTitle={false}
        selected={reviewData.ratings.catfish}
      />
    </Flex>
  );
}
