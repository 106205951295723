import { useState } from "react";
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { MultiSelect } from "./MultiSelect";
import { useReviewRef } from "../../providers/use-review";
import { FLAG_CATEGORIES } from "../../data/review";

export function Flags() {
  const [tabIndex, setTabIndex] = useState(0);

  const { reviewData, setReviewData } = useReviewRef();

  const greenFlags = FLAG_CATEGORIES.GREEN_FLAGS.options.map((option) => ({
    systemName: option.systemName,
    displayName: option.prettyName,
    icon: option.emoji!,
  }));
  const redFlags = FLAG_CATEGORIES.RED_FLAGS.options.map((option) => ({
    systemName: option.systemName,
    displayName: option.prettyName,
    icon: option.emoji!,
  }));

  const handleGreenFlagsChange = (selected: string[]) => {
    setReviewData({
      ...reviewData,
      flags: {
        ...reviewData.flags,
        GREEN_FLAGS: selected,
      },
    });
  };

  const handleRedFlagsChange = (selected: string[]) => {
    setReviewData({
      ...reviewData,
      flags: {
        ...reviewData.flags,
        RED_FLAGS: selected,
      },
    });
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
        variant="soft-rounded"
        colorScheme="pink"
        size="sm"
        mt={1}
        h="100%"
      >
        <TabList justifyContent="center">
          <Tab mx={1} py={1} px={3}>
            Green
          </Tab>
          <Tab mx={1} py={1} px={3}>
            Red
          </Tab>
        </TabList>
        <TabPanels h="90%">
          <TabPanel py={0} px={0} h="100%">
            <MultiSelect
              selected={reviewData.flags.GREEN_FLAGS ?? []}
              options={greenFlags.map((flag) => ({
                key: flag.systemName,
                value: flag.displayName,
                icon: flag.icon,
              }))}
              onChange={(selected) => {
                handleGreenFlagsChange(selected);
              }}
            />
          </TabPanel>
          <TabPanel py={0} px={0} h="100%">
            <MultiSelect
              selected={reviewData.flags.RED_FLAGS ?? []}
              options={redFlags.map((flag) => ({
                key: flag.systemName,
                value: flag.displayName,
                icon: flag.icon,
              }))}
              onChange={(selected) => {
                handleRedFlagsChange(selected);
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
