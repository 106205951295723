import { Button, Flex, Modal, Text } from "@chakra-ui/react";
import {
  faArrowRightFromBracket,
  faEnvelope,
  faHeart,
  faHistory,
  faPaperPlane,
  faPlus,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthRef } from "../../providers/use-auth";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useState } from "react";
import { useDateRate } from "../../providers/use-daterate";

function QRCodeReferral({ referralCode }: { referralCode?: string }) {
  if (!referralCode) {
    return null;
  }

  return (
    <Flex padding={4} bg="white" borderRadius="lg">
      <QRCode
        size={192}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={`${window.location.origin}/login?referralCode=${referralCode}`}
        viewBox={`0 0 192 192`}
      />
    </Flex>
  );
}

export function SettingsModal({
  userModalOpen,
  setUserModalOpen,
}: {
  userModalOpen: boolean;
  setUserModalOpen: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { logout } = useAuthRef();
  const { userDetailsQuery } = useDateRate();
  const [showQRCode, setShowQRCode] = useState(false);

  const referralCode = userDetailsQuery?.data?.details?.referralCode;

  if (showQRCode) {
    return (
      <Flex>
        <Modal
          isOpen={userModalOpen}
          onClose={() => setUserModalOpen(false)}
          closeOnOverlayClick={true}
        >
          <Flex
            position="fixed"
            left={0}
            top={0}
            right={0}
            bottom={0}
            zIndex={1000000}
            backgroundColor="rgba(82, 27, 65, 0.5)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setShowQRCode(false);
              setUserModalOpen(false);
            }}
          >
            <QRCodeReferral referralCode={referralCode} />
          </Flex>
        </Modal>
      </Flex>
    );
  }

  return (
    <Flex>
      <Modal isOpen={userModalOpen} onClose={() => setUserModalOpen(false)}>
        <Flex
          position="fixed"
          left={0}
          top={0}
          right={0}
          bottom={0}
          zIndex={1000000}
          backgroundColor="rgba(82, 27, 65, 0.5)"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Flex flex={1} onClick={() => setUserModalOpen(false)} />
          <Flex
            flexDirection="column"
            bg="white"
            borderTopRadius="20px"
            overflow="hidden"
            boxShadow="0px -4px 10px rgba(0, 0, 0, 0.1)"
          >
            <Flex flexDirection="column" width="100%" py={6} px={4} gap={4}>
              <Button
                leftIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  logout();
                  setUserModalOpen(false);
                }}
                py={6}
                color="red.500"
              >
                Logout
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon icon={faQrcode} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  setShowQRCode(true);
                }}
                py={6}
              >
                <Text flex={1} textAlign="left">
                  QR Referral
                </Text>
                <Text textAlign="right" fontWeight="regular" color="gray.500">
                  +10 views
                </Text>
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={async () => {
                  if (navigator.share) {
                    await navigator.share({
                      title: "Share Referral",
                      text: `Want to screen your dates with AI? Check out Rate Your Date with my referral code: ${referralCode}!`,
                      url: `${window.location.origin}/login?referralCode=${referralCode}`,
                    });
                  } else if (navigator.clipboard) {
                    await navigator.clipboard.writeText(
                      `${window.location.origin}/login?referralCode=${referralCode}`
                    );
                  }
                }}
                py={6}
              >
                <Text flex={1} textAlign="left">
                  Text Referral
                </Text>
                <Text textAlign="right" fontWeight="regular" color="gray.500">
                  +10 views
                </Text>
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  window.open("mailto:ops@rateyourdate.xyz", "_blank");
                  setUserModalOpen(false);
                }}
                py={6}
              >
                Contact Us
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon color="#7878da" icon={faHistory} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  navigate("/history");
                  setUserModalOpen(false);
                }}
                py={6}
              >
                Recent Views
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon color="#ED64A6" icon={faHeart} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => {
                  navigate("/rankings");
                  setUserModalOpen(false);
                }}
                py={6}
              >
                Your Dates
              </Button>
              <Button
                leftIcon={<FontAwesomeIcon icon={faPlus} />}
                colorScheme="pink"
                size="lg"
                justifyContent="flex-start"
                fontWeight="semibold"
                onClick={() => {
                  navigate("/");
                  setUserModalOpen(false);
                }}
              >
                Search Your Date
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
}
