import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../constants";

type AuthState = "login" | "code" | "loggedIn";

type AuthRefContextType = {
  jwt?: string;
  jwtLoading: boolean;

  authState: AuthState;
  submitNumber: (phoneNumber: string) => void;
  submitCode: ({
    phoneNumber,
    referralCode,
    code,
  }: {
    phoneNumber: string;
    code: string;
    referralCode?: string;
  }) => void;
  logout: () => void;
  isLoginLoading: boolean;
  callbackFailure: string;
};

const AuthContext = createContext<AuthRefContextType>({
  jwtLoading: true,
  submitNumber: () => {},
  submitCode: () => {},
  authState: "login",
  isLoginLoading: false,
  callbackFailure: "",
  logout: () => {},
});

export const useAuthRef = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [jwtLoading, setJwtLoading] = useState(true);
  const [jwt, setJwt] = useState<string>();
  const [authState, setAuthState] = useState<AuthState>("login");
  const [callbackFailure, setCallbackFailure] = useState<string>("");

  const setJwtWrapped = (jwt: string) => {
    localStorage.setItem("jwt", jwt);
    setJwt(jwt);
  };

  const { mutate: submitNumber, isPending: isNumberLoading } = useMutation({
    mutationFn: async (phoneNumber: string) => {
      const response = await fetch(`${API_URL}/v1/auth/init`, {
        method: "POST",
        body: JSON.stringify({ phoneNumber }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to submit number");
      }
    },
    onSuccess: () => {
      setAuthState("code");
    },
  });

  const { mutate: submitCode, isPending: isCodeLoading } = useMutation({
    mutationFn: async ({
      phoneNumber,
      code,
      referralCode,
    }: {
      phoneNumber: string;
      code: string;
      referralCode?: string;
    }) => {
      const response = await fetch(`${API_URL}/v1/auth/verify`, {
        method: "POST",
        body: JSON.stringify({ code, phoneNumber }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const { jwtToken } = await response.json();
        setJwtWrapped(jwtToken);
        return;
      }
      throw new Error("Failed to verify code");
    },
    onError: () => {
      setCallbackFailure("Failed to verify code - double check your texts.");
    },
    onSuccess: () => {
      setAuthState("loggedIn");
    },
  });

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      setJwtWrapped(jwt);
    }
    setJwtLoading(false);
  }, []);

  const logout = () => {
    console.log("logging out");
    localStorage.removeItem("jwt");
    setJwt(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        jwt,
        jwtLoading,
        authState,
        submitNumber,
        submitCode,
        isLoginLoading: isNumberLoading || isCodeLoading,
        callbackFailure,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
