import { Button, Flex, Text, Image } from "@chakra-ui/react";
import { faRunning, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReviewRef } from "../../providers/use-review";

type MeetingRow = {
  systemName: string;
  prettyName?: string;
  img?: string;
  icon?: IconDefinition;
  emoji?: string;
  bg: string;
  color: string;
};

export const MEETING_DATA: MeetingRow[] = [
  {
    systemName: "hinge",
    img: "/hinge.png",
    bg: "#ddd",
    color: "white",
  },
  {
    systemName: "tinder",
    img: "/tinder.png",
    bg: "#f7acc1",
    color: "white",
  },
  {
    systemName: "bumble",
    img: "/bumble.png",
    bg: "#f7eab6",
    color: "black",
  },
  {
    systemName: "inperson",
    prettyName: "In Person",
    icon: faRunning,
    color: "white",
    bg: "purple.200",
  },
  {
    systemName: "other",
    prettyName: "Other",
    emoji: "🤔",
    color: "white",
    bg: "green.200",
  },
];

export function Meeting({
  meeting,
  setMeeting,
}: {
  meeting: string | null;
  setMeeting: (value: string | null) => void;
}) {
  const { reviewData, setReviewData } = useReviewRef();

  const handleSetMeeting = (value: string | null) => {
    setMeeting(value);
    if (value === null) return;
    setReviewData({
      ...reviewData,
      dropdown: { ...reviewData.dropdown, MEET_LOCATION: value },
    });
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      py={6}
      px={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      bg="gray.50"
    >
      <Text fontSize="xl" fontWeight="semibold" mb={4} color="gray.800">
        Where did you meet?
      </Text>
      <Flex
        width="100%"
        justify="space-evenly"
        maxWidth="320px"
        flexWrap="wrap"
      >
        {MEETING_DATA.map((meetingRow) => (
          <Button
            key={meetingRow.systemName}
            bg={
              meeting === null || meeting === meetingRow.systemName
                ? meetingRow.bg
                : "gray.400"
            }
            color={meetingRow.color}
            size="md"
            flex="0 0 30%"
            mb={3}
            borderRadius="full"
            _hover={{ bg: meetingRow.bg }}
            _active={{ transform: "translateY(-2px)", boxShadow: "lg" }}
            transition="all 0.2s"
            onClick={() => handleSetMeeting(meetingRow.systemName)}
            gap={1}
          >
            {meetingRow.img && <Image src={meetingRow.img} w={5} />}
            {meetingRow.icon && <FontAwesomeIcon icon={meetingRow.icon} />}
            {meetingRow.emoji && <Text>{meetingRow.emoji}</Text>}
            {meetingRow.prettyName && (
              <Text fontSize={"xs"}>{meetingRow.prettyName}</Text>
            )}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}
