import { useEffect, useState } from "react";
import { ReviewProvider } from "../../providers/use-review";
import { ReviewRouter } from "./Review";
import { ImageFlowProvider } from "../../providers/use-image-flow";
import Rate from "./Rate";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileProvider, useProfile } from "../../providers/use-profile";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { DateRateProvider } from "../../providers/use-daterate";
import { useAuthRef } from "../../providers/use-auth";
import { ReviewModal } from "../review-modal/ReviewModal";

function ProfileInternal() {
  const [isRating, setIsRating] = useState(false);
  const { profileData } = useProfile();

  if (!profileData) {
    return (
      <Flex
        height={"100dvh"}
        width={"100vw"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={5}
        flexDirection={"column"}
      >
        <Spinner size="xl" color="pink.500" />
        <Text>loading date...</Text>
      </Flex>
    );
  }

  // if (isRating) {
  //   return (
  //     <ReviewProvider>
  //       <ReviewRouter onClose={() => setIsRating(false)} />
  //     </ReviewProvider>
  //   );
  // }

  return (
    <ReviewProvider>
      <ReviewModal isOpen={isRating} onClose={() => setIsRating(false)} />
      <Rate onReview={() => setIsRating(true)} />
    </ReviewProvider>
  );
}

export function Profile() {
  const { profileId } = useParams();
  const navigate = useNavigate();

  const { jwt, jwtLoading } = useAuthRef();

  useEffect(() => {
    if (!jwt && !jwtLoading) {
      navigate("/login");
    }
  }, [jwt, navigate, jwtLoading]);

  return (
    <DateRateProvider>
      <ImageFlowProvider>
        <ProfileProvider profileId={profileId}>
          <ProfileInternal />
        </ProfileProvider>
      </ImageFlowProvider>
    </DateRateProvider>
  );
}
