import {
  Button,
  Collapse,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useAuthRef } from "../../providers/use-auth";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { SloganHeader } from "../common/SloganHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";

function OptCode({
  pinInput,
  setPinInput,
  callbackFailure,
}: {
  pinInput: string;
  setPinInput: (pin: string) => void;
  callbackFailure: string;
}) {
  return (
    <Flex direction="column" alignItems="center" gap={4}>
      <Text fontSize="lg" fontWeight="bold">
        enter the code we sent you
      </Text>
      <OtpInput
        value={pinInput}
        onChange={setPinInput}
        numInputs={6}
        placeholder="❤️"
        renderSeparator={<span style={{ width: "10px" }}> </span>}
        renderInput={(props, i) => (
          <Input
            {...props}
            placeholder="❤️"
            _placeholder={{
              opacity: 0.5,
            }}
            _focus={{
              _placeholder: {
                color: "transparent",
              },
            }}
            bg="white"
            size={"lg"}
            textAlign={"center"}
            minWidth={"40px"}
            px={0}
            mx={0}
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            autoComplete="one-time-code"
            autoFocus
          />
        )}
        shouldAutoFocus={true}
      />
      {callbackFailure && (
        <Text
          color="red.500"
          fontSize="sm"
          fontWeight="medium"
          animation="shake 0.82s cubic-bezier(.36,.07,.19,.97) both"
          key={callbackFailure}
        >
          {callbackFailure}
        </Text>
      )}
    </Flex>
  );
}

function PageOneInput({
  onSubmit,
  numberError,
}: {
  onSubmit: (phoneNumber: string, referralCode?: string) => void;
  numberError: string;
}) {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string>("");

  return (
    <Flex
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      gap={5}
      flexDirection={"column"}
      width={"100%"}
    >
      <Flex flexDirection={"column"} gap={2}>
        <ReferralInput
          referralCode={referralCode}
          setReferralCode={setReferralCode}
        />
        <PhoneInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          numberError={numberError}
          onSubmit={() => onSubmit(phoneNumber)}
        />
      </Flex>
      <Button
        bg="pink.400"
        color="white"
        width={"100%"}
        _hover={{ bg: "pink.500" }}
        onClick={() => onSubmit(phoneNumber)}
      >
        continue
      </Button>
    </Flex>
  );
}

function PhoneInput({
  phoneNumber,
  setPhoneNumber,
  numberError,
  onSubmit,
}: {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  numberError: string;
  onSubmit: () => void;
}) {
  return (
    <Flex>
      <Flex width={"100%"} flexDirection={"column"} gap={0}>
        <InputGroup width={"100%"}>
          <InputLeftAddon bg="gray.100">+1</InputLeftAddon>
          <Input
            type="tel"
            placeholder="your phone number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            bg="white"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
        </InputGroup>
        <Text color={"gray.700"} fontWeight={"100"}>
          we'll never spam you
        </Text>
      </Flex>
      {numberError && (
        <Text
          color="red.500"
          fontSize="sm"
          fontWeight="medium"
          width="100%"
          textAlign="center"
          padding={2}
          borderRadius="md"
          backgroundColor="red.50"
        >
          {numberError}
        </Text>
      )}
    </Flex>
  );
}

function ReferralInput({
  referralCode,
  setReferralCode,
}: {
  referralCode: string;
  setReferralCode: (referralCode: string) => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("referralCode")) {
      setReferralCode(searchParams.get("referralCode") || "");
    }
  }, [searchParams, setReferralCode]);

  return (
    <Flex alignItems={"center"} flexDirection={"column"} width={"100%"}>
      <Flex
        width="100%"
        justifyContent={"flex-end"}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "" : "+ referral code"}
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ width: "100%" }}>
        <Input
          type="text"
          placeholder="enter referral code"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          width={"100%"}
          bg="white"
        />
      </Collapse>
    </Flex>
  );
}

function LoginInner() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string | undefined>(
    undefined
  );
  const [numberError, setNumberError] = useState<string>("");
  const [pinInput, setPinInput] = useState<string>("");

  const {
    jwt,
    submitNumber,
    submitCode,
    isLoginLoading,
    authState,
    callbackFailure,
  } = useAuthRef();
  const navigate = useNavigate();

  const submitNumberWrapper = (phoneNumber: string, referralCode?: string) => {
    phoneNumber = phoneNumber.replace(/\D/g, "");
    const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    if (phoneRegex.test(phoneNumber)) {
      submitNumber(phoneNumber);
      setPhoneNumber(phoneNumber);
      setReferralCode(referralCode);
    } else {
      setNumberError("Please enter a valid 10-digit US phone number.");
    }
  };

  const setPinWrapper = (pin: string) => {
    const digitsOnly = pin.replace(/\D/g, "");
    setPinInput(digitsOnly);
    if (digitsOnly.length === 6) {
      submitCode({ phoneNumber, code: digitsOnly, referralCode });
    }
  };

  useEffect(() => {
    if (authState === "loggedIn") {
      navigate("/");
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (jwt) {
      navigate("/");
    }
  }, [jwt]);

  if (isLoginLoading) {
    return <Spinner />;
  }

  if (authState === "code") {
    return (
      <OptCode
        pinInput={pinInput}
        setPinInput={setPinWrapper}
        callbackFailure={callbackFailure}
      />
    );
  }

  return (
    <Flex flexDirection={"column"} gap={2}>
      <PageOneInput onSubmit={submitNumberWrapper} numberError={numberError} />
    </Flex>
  );
}

function LoginInternal() {
  return (
    <Flex
      height={"100dvh"}
      width={"100vw"}
      bgGradient="linear(to-b, white, pink.100)"
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      padding={10}
    >
      <Flex flexDirection={"column"} width={"100%"}>
        <SloganHeader />
      </Flex>
      <LoginInner />
    </Flex>
  );
}

export function Login() {
  return <LoginInternal />;
}
