import { useState } from "react";
import { ReviewCategory } from "../../types/review";
import { useProfile } from "../../providers/use-profile";
import { Text, Flex, Collapse, Icon, Divider } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { createTheme, Rating, ThemeProvider } from "@mui/material";

const theme = createTheme({});

export function ReviewStarOptions({
  onChange,
  category,
  showTitle = true,
  selected,
}: {
  onChange: (rating: number) => void;
  category: ReviewCategory;
  selected?: number;
  showTitle?: boolean;
}) {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const { profileData } = useProfile();

  const name = profileData?.name.toLowerCase() ?? "your date";

  const prettyName = category.prettyName;
  const options = category.options;

  return (
    <Flex
      flexDirection="column"
      gap={3}
      width="100%"
      maxWidth="400px"
      mx="auto"
      minHeight="100%"
      p={4}
    >
      {showTitle && (
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Text
            fontSize="2xl"
            fontWeight="bold"
            bgGradient="linear(to-r, pink.200, pink.100)"
            bgClip="text"
          >
            rate {name}'s&nbsp;
            <Text
              as="span"
              bgGradient="linear(to-r, pink.500, orange.500)"
              bgClip="text"
            >
              {prettyName}
            </Text>
          </Text>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        gap={3}
        justifyContent="center"
        height="100%"
        w="100%"
      >
        {options.map((option, index) => (
          <Flex
            key={index}
            flexDirection="column"
            borderWidth={1}
            borderRadius="md"
            w="100%"
            p={4}
            bg={selected === option.stars ? "gray.200" : "transparent"}
            cursor="pointer"
            transition="all 0.2s"
            boxShadow="sm"
            onClick={() => {
              onChange(option.stars);
              setExpandedIndex(-1);
            }}
            _active={{
              transform: "scale(0.9)",
              transition: "transform 0.1s",
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flex={1} alignItems="center">
                <Flex alignItems="center" height="" position="relative">
                  <Flex
                    position="absolute"
                    width="calc(100% + 60px)"
                    height="calc(100% + 100px)"
                    left="-20px"
                    top="-30px"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedIndex(expandedIndex === index ? -1 : index);
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </Flex>
                <Icon
                  as={QuestionIcon}
                  aria-label="More information"
                  color="gray.500"
                />
                <Divider
                  orientation="vertical"
                  height="20px"
                  mx={2}
                  borderColor="gray.400"
                />
                <Flex flexDirection="column" width="100%">
                  <Text
                    fontWeight="bold"
                    fontSize={["sm", "md"]}
                    noOfLines={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {option.title}
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems="center" flexShrink={0}>
                <ThemeProvider theme={theme}>
                  <Rating
                    name={`option-${index}`}
                    defaultValue={option.stars}
                    readOnly
                    size="small"
                  />
                </ThemeProvider>
              </Flex>
            </Flex>
            <Collapse in={expandedIndex === index} animateOpacity>
              <Text fontSize="sm" color="gray.600" mt={2}>
                {option.description}
              </Text>
            </Collapse>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
