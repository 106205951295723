import { Flex } from "@chakra-ui/react";
import { Select } from "./Select";
import { useReviewRef } from "../../providers/use-review";
import { TILE_CATEGORIES } from "../../data/review";

export function DateType({ onClose }: { onClose: () => void }) {
  const { reviewData, setReviewData } = useReviewRef();

  const handleSetDateType = (value: string | null) => {
    onClose();
    if (value === null) return;
    setReviewData({
      ...reviewData,
      dropdown: { ...reviewData.dropdown, DATE_TYPE: value },
    });
  };

  const dateTypeOptions = TILE_CATEGORIES.DATE_TYPE.options;

  const options = dateTypeOptions.map((option) => ({
    key: option.systemName,
    value: option.prettyName,
    icon: option.emoji,
  }));

  return (
    <Flex flexDirection="column" flex={1}>
      <Select
        options={options}
        selected={reviewData.dropdown.DATE_TYPE}
        onChange={handleSetDateType}
      />
    </Flex>
  );
}
