import { ProfileResponse } from "../../types/image-flow";
import { ActiveOn } from "./ActiveOn";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Card,
} from "@chakra-ui/react";
import { ProfileActivity } from "./Activity";

export function QuickTabs({ profileData }: { profileData: ProfileResponse }) {
  return (
    <Card
      width="100%"
      flexDirection="column"
      gap={3}
      alignItems="center"
      borderRadius="lg"
      boxShadow="md"
    >
      <Tabs isFitted width="100%" colorScheme="pink">
        <TabList>
          <Tab style={{ fontWeight: "bold" }}>Active Apps</Tab>
          <Tab style={{ fontWeight: "bold" }}>Dating Activity</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ActiveOn profileData={profileData} />
          </TabPanel>
          <TabPanel>
            <ProfileActivity profileData={profileData} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}
