import {
  Badge,
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  Box,
} from "@chakra-ui/react";
import { createTheme, Rating, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronLeft,
  faDollar,
  faEdit,
  faExclamationCircle,
  faFaceLaugh,
  faFeather,
  faFish,
  faHeart,
  faHome,
  faLock,
  faMask,
  faPaperPlane,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "react-calendar-heatmap/dist/styles.css";
import { Header } from "../common/Header";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useProfile } from "../../providers/use-profile";
import { ProfileResponse } from "../../types/image-flow";
import { useImageFlow } from "../../providers/use-image-flow";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { QuickTabs } from "../profile/QuickTabs";
import { ReviewData } from "../../types/review";
import { RatingRow } from "../profile/RatingRow";
import { DropdownRow } from "../profile/DropdownRow";
import { FlagRow } from "../profile/FlagRow";

const theme = createTheme({});

function ProfileHeader({
  profileData,
  reviewData,
  reviewMode,
}: {
  profileData: ProfileResponse;
  reviewData?: ReviewData;
  reviewMode?: boolean;
}) {
  const [key, setKey] = useState(0);
  const numberReviews = profileData?.reviewData?.numberReviews ?? "?";
  const numberViews = profileData?.reviewData?.numberViews ?? "?";
  const totalScores = Object.values(reviewData?.ratings ?? {}).reduce<number>(
    (acc, curr) => acc + (typeof curr === "number" ? 1 : 0),
    0
  );
  const totalRating =
    Object.values(reviewData?.ratings ?? {}).reduce<number>(
      (acc, curr) => acc + curr,
      0
    ) / totalScores;

  useEffect(() => {
    setKey(totalRating);
  }, [totalRating]);

  const images = profileData?.imgUrls.map((url) => ({
    original: url,
    thumbnail: url,
  }));
  console.log(profileData?.imgUrls);
  return (
    <Flex
      alignItems={"center"}
      width={"calc(100vw - 20px)"}
      aspectRatio={"1/1"}
      maxWidth={"40em"}
      gap={2}
      flexDirection={"column"}
      position={"relative"}
    >
      <ImageGallery
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={false}
        items={images}
        showBullets={true}
      />
      <Flex
        style={{
          borderRadius: "5px",
          width: "calc(100vw - 20px)",
          height: "100%",
          maxWidth: "40em",
        }}
        pointerEvents={"none"}
        position={"absolute"}
      >
        <Flex
          flexDirection={"row"}
          gap={0}
          width={"100%"}
          height={"100%"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          padding={5}
        >
          <Flex flexDirection={"column"} gap={0}>
            <Flex alignItems={"center"} gap={2}>
              <Text
                fontSize={"1.5rem"}
                fontWeight={"bold"}
                color={"white"}
                textShadow="2px 2px 4px rgba(0,0,0,0.5)"
              >
                {(profileData?.name?.charAt(0).toUpperCase() ?? "") +
                  (profileData?.name?.slice(1) ?? "")}
              </Text>

              {!reviewMode && (
                <Flex flexDirection={"row"}>
                  <ThemeProvider theme={theme}>
                    <Rating
                      key={key}
                      style={{
                        fontSize: "1.25rem",
                        filter: "drop-shadow(4px 4px 4px rgba(0,0,0,0.5))",
                      }}
                      name="half-rating-read"
                      defaultValue={totalRating}
                      precision={0.5}
                      readOnly
                    />
                  </ThemeProvider>
                </Flex>
              )}
            </Flex>
            {!reviewMode && (
              <Flex
                w="100%"
                flexDirection={"row"}
                gap={2}
                justifyContent={"flex-start"}
              >
                {profileData?.reviewData?.hasReviewed && (
                  <Badge
                    colorScheme="green"
                    fontSize={"0.65rem"}
                    fontWeight={"bold"}
                    paddingX={2}
                    paddingY={1}
                    borderRadius={"full"}
                    height={"fit-content"}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp; reviewed
                  </Badge>
                )}
                <Badge
                  size="xs"
                  colorScheme="pink"
                  fontSize={"0.65rem"}
                  fontWeight={"bold"}
                  paddingX={2}
                  paddingY={1}
                  height={"fit-content"}
                  borderRadius={"full"}
                >
                  <Text>
                    {numberReviews} review{numberReviews === 1 ? "" : "s"}
                  </Text>
                </Badge>
                <Badge
                  size="xs"
                  colorScheme="yellow"
                  fontSize={"0.65rem"}
                  fontWeight={"bold"}
                  paddingX={2}
                  paddingY={1}
                  height={"fit-content"}
                  borderRadius={"full"}
                >
                  <Text>
                    {numberViews} view
                    {numberViews === 1 ? "" : "s"}
                  </Text>
                </Badge>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function ProfileStars({
  profileData,
  reviewData,
}: {
  profileData: ProfileResponse;
  reviewData?: ReviewData;
}) {
  const { ratings } = reviewData ?? ({ ratings: {} } as ReviewData);

  return (
    <Flex
      alignItems={"flex-start"}
      width={"100%"}
      gap={2}
      flexDirection={"column"}
    >
      <Flex
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={2}
      >
        {/* <RatingRow
          category="safety"
          rating={ratings.safety}
          icon={faLock}
          iconColor={"#86cfb4"}
        /> */}
        {/* <RatingRow
          category="humor"
          rating={ratings.humor}
          icon={faFaceLaugh}
          iconColor={"#cd8bff"}
        /> */}
        {/* <RatingRow
          category="cheap-expensive"
          rating={ratings["cheap-expensive"]}
          icon={faDollar}
          iconColor={"#239836"}
        /> */}
        <RatingRow
          category="catfish"
          rating={ratings.catfish}
          icon={faFish}
          iconColor={"#ff8d02"}
        />
        <RatingRow
          category="bedroom"
          rating={ratings.bedroom}
          icon={faHeart}
          iconColor={"#FF69B4"}
        />
        <RatingRow
          category="kinkiness"
          rating={ratings.kinkiness}
          icon={faMask}
          iconColor={"#d84747"}
        />
      </Flex>
    </Flex>
  );
}

function ProfileDropdowns({
  profileData,
  reviewData,
}: {
  profileData: ProfileResponse;
  reviewData?: ReviewData;
}) {
  let dropdowns: any = reviewData?.dropdown;

  // @ts-ignore
  if (typeof Object.values(dropdowns).at(0)?.name === "string") {
    dropdowns = Object.fromEntries(
      Object.entries(dropdowns).map(([k, v]) => [
        k,
        [
          {
            cnt: "1",
            // @ts-ignore
            name: v.name,
            // @ts-ignore
            value: v.value,
          },
        ],
      ])
    );
  }

  return (
    <Flex
      alignItems={"flex-start"}
      width={"100%"}
      gap={2}
      flexDirection={"column"}
    >
      <DropdownRow
        key={dropdowns.DATE_TYPE?.value}
        category="DATE_TYPE"
        selections={dropdowns?.DATE_TYPE}
      />
      <DropdownRow
        key={dropdowns.STAR_SIGN?.value}
        category="STAR_SIGN"
        selections={dropdowns?.STAR_SIGN}
      />
    </Flex>
  );
}

function ProfileFlags({
  profileData,
  reviewData,
}: {
  profileData: ProfileResponse;
  reviewData?: ReviewData;
}) {
  let flags: any = reviewData?.flags;

  // @ts-ignore
  if (typeof Object.values(flags).at(0)?.name === "string") {
    flags = Object.fromEntries(
      Object.entries(flags).map(([k, v]) => [
        k,
        [
          {
            cnt: "1",
            // @ts-ignore
            name: v.name,
            // @ts-ignore
            value: v.value,
          },
        ],
      ])
    );
  }
  return (
    <Flex
      alignItems={"flex-start"}
      width={"100%"}
      gap={2}
      flexDirection={"column"}
    >
      <FlagRow category="GREEN_FLAGS" selections={flags?.GREEN_FLAGS} />
      <FlagRow category="RED_FLAGS" selections={flags?.RED_FLAGS} />
      <FlagRow category="TOPICS" selections={flags?.TOPICS} />
      <FlagRow category="DEALBREAKERS" selections={flags?.DEALBREAKERS} />
    </Flex>
  );
}

function AddReviewButton({
  profileData,
  onReview,
}: {
  profileData: ProfileResponse;
  onReview: () => void;
}) {
  const hasReviewed = profileData?.reviewData?.hasReviewed;
  return (
    <Flex
      color={"white"}
      bg={"pink.400"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        fontSize: "1.3rem",
        position: "absolute",
        bottom: 20,
        right: 20,
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
      }}
      _hover={{
        transform: "scale(1.05)",
        transition: "transform 0.2s ease-in-out",
      }}
      _active={{
        transform: "scale(0.95)",
        transition: "transform 0.1s ease-in-out",
      }}
      onClick={onReview}
    >
      {!hasReviewed ? (
        <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
          <FontAwesomeIcon icon={faFeather} size={"xl"} />
          <Text fontSize={"0.5em"} fontWeight={"bold"}>
            review
          </Text>
        </Flex>
      ) : (
        <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
          <FontAwesomeIcon icon={faEdit} size={"xl"} />
          <Text fontSize={"0.5em"} fontWeight={"bold"}>
            edit
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

function AddShareButton({ profileData }: { profileData: ProfileResponse }) {
  const onShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "Share Profile",
        text: `Check out ${profileData.name} on Rate Your Date!`,
        url: `${window.location.origin}/profile/${profileData.profileId}`,
      });
    }
  };

  return (
    <Flex
      color={"white"}
      bg={"pink.400"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        fontSize: "1.3rem",
        position: "absolute",
        bottom: 20,
        left: 20,
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
      }}
      _hover={{
        transform: "scale(1.05)",
        transition: "transform 0.2s ease-in-out",
      }}
      _active={{
        transform: "scale(0.95)",
        transition: "transform 0.1s ease-in-out",
      }}
      onClick={onShare}
    >
      <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
        <FontAwesomeIcon icon={faPaperPlane} size={"lg"} />
        <Text fontSize={"0.5em"} fontWeight={"bold"}>
          share
        </Text>
      </Flex>
    </Flex>
  );
}

function ProfileNewInquiry({
  profileData,
  navigate,
  associateProfile,
}: {
  profileData: ProfileResponse;
  navigate: (path: string) => void;
  associateProfile: (profileId: string) => void;
}) {
  return (
    <Flex direction="column" align="center" width="100%" height="100vh" gap={5}>
      <ProfileHeader
        profileData={profileData}
        reviewData={profileData.reviewData as any as ReviewData}
      />
      <Flex
        direction="column"
        align="center"
        justify="center"
        width="100%"
        px={4}
        gap={4}
      >
        <Flex flexDirection={"column"} gap={0}>
          <Text
            fontSize="xl"
            fontWeight="semibold"
            textAlign="center"
            color="gray.800"
          >
            is this your date?
          </Text>
          <Text
            fontSize="md"
            fontWeight="medium"
            color="gray.500"
            textAlign={"center"}
          >
            selecting yes will deduct 1 from your view limit
          </Text>
        </Flex>
        <Flex direction="column" width="100%" maxWidth="300px" gap={4}>
          <Button
            colorScheme="teal"
            width="100%"
            size="lg"
            borderRadius="full"
            leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
            transition="all 0.2s"
            onClick={() => associateProfile(profileData.profileId)}
          >
            yes, it's them
          </Button>
          <Button
            colorScheme="red"
            width="100%"
            size="lg"
            borderRadius="full"
            variant="outline"
            leftIcon={<FontAwesomeIcon icon={faTimesCircle} />}
            _hover={{ bg: "red.50", transform: "translateY(-2px)" }}
            transition="all 0.2s"
            onClick={() => navigate("/select")}
          >
            no, it's not
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

function ViewLimitReached({ onReview }: { onReview: () => void }) {
  return (
    <Flex flexDirection={"column"} gap={2}>
      <Box mb={6}>
        <Icon
          as={FontAwesomeIcon}
          icon={faExclamationCircle}
          fontSize="4xl"
          color="pink.500"
          mb={4}
        />
        <Text fontSize="xl" fontWeight="bold" color="gray.800" mb={2}>
          View Limit Reached
        </Text>
        <Text fontSize="md" color="gray.600">
          Leave a review to unlock more profile views
        </Text>
      </Box>
      <Button
        colorScheme="pink"
        size="lg"
        width="100%"
        maxWidth="300px"
        height="56px"
        borderRadius="full"
        fontWeight="semibold"
        leftIcon={<FontAwesomeIcon icon={faFeather} />}
        onClick={onReview}
        _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
        transition="all 0.2s"
      >
        Leave a Review
      </Button>
    </Flex>
  );
}

function RatingContextButtons({
  reviewContext,
  setReviewContext,
}: {
  reviewContext: "all" | "verified" | "my";
  setReviewContext: (context: "all" | "verified" | "my") => void;
}) {
  const selectedStyle = { bg: "gray.500", color: "white" };
  const unselectedStyle = { bg: "gray.100", color: "gray.800" };
  return (
    <Flex flexDirection={"row"} gap={2} justifyContent={"center"}>
      <Button
        {...(reviewContext === "all" ? selectedStyle : unselectedStyle)}
        size="sm"
        borderRadius="full"
        onClick={() => setReviewContext("all")}
        _hover={{ bg: reviewContext === "all" ? "gray.700" : "gray.200" }}
        transition="all 0.2s"
      >
        All Reviews
      </Button>
      <Button
        {...(reviewContext === "verified" ? selectedStyle : unselectedStyle)}
        size="sm"
        borderRadius="full"
        onClick={() => setReviewContext("verified")}
        _hover={{ bg: reviewContext === "verified" ? "gray.700" : "gray.200" }}
        transition="all 0.2s"
      >
        Verified Reviews
      </Button>
      <Button
        {...(reviewContext === "my" ? selectedStyle : unselectedStyle)}
        size="sm"
        borderRadius="full"
        onClick={() => setReviewContext("my")}
        _hover={{ bg: reviewContext === "my" ? "gray.700" : "gray.200" }}
        transition="all 0.2s"
      >
        My Review
      </Button>
    </Flex>
  );
}

function ProfileRatingBody({
  profileData,
  reviewData,
  isNew,
  resetState,
  navigate,
  onReview,
}: {
  profileData: ProfileResponse;
  reviewData?: ReviewData;
  isNew: boolean;
  resetState: () => void;
  navigate: (path: string) => void;
  onReview: () => void;
}) {
  const [reviewContext, setReviewContext] = useState<"all" | "verified" | "my">(
    "all"
  );

  const reviewDataContext = useMemo(() => {
    if (reviewContext === "my") {
      return reviewData;
    }
    return profileData.reviewData as any as ReviewData;
  }, [reviewContext, reviewData, profileData.reviewData]);

  if (!reviewDataContext) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100%"
        px={4}
        py={8}
        maxWidth="100%"
        mx="auto"
        gap={5}
      >
        <ProfileHeader
          profileData={profileData}
          reviewData={reviewDataContext}
        />
        <ViewLimitReached onReview={onReview} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <AddReviewButton profileData={profileData} onReview={onReview} />
      <AddShareButton profileData={profileData} />
      <ProfileHeader profileData={profileData} reviewData={reviewDataContext} />
      <QuickTabs profileData={profileData} />
      <RatingContextButtons
        reviewContext={reviewContext}
        setReviewContext={setReviewContext}
      />
      <ProfileFlags profileData={profileData} reviewData={reviewDataContext} />
      <ProfileDropdowns
        profileData={profileData}
        reviewData={reviewDataContext}
      />
      <ProfileStars profileData={profileData} reviewData={reviewDataContext} />
      <Flex minHeight={"30px"} width={"100%"} />
    </Flex>
  );
}

export default function ProfileView({ onReview }: { onReview: () => void }) {
  const { loadingProfileData, profileData, reviewData } = useProfile();
  const { searchData, resetState, associateProfile, loadingAssociation } =
    useImageFlow();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const isNew = state?.isNew;
  const isAssociated = state?.isAssociated;

  const backPath = state?.backPath;

  if (loadingProfileData || !profileData || loadingAssociation) {
    return (
      <Flex
        height={"100dvh"}
        width={"100vw"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      width={"100vw"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      padding={10}
      display={"flex"}
      flexDirection={"column"}
      gap={10}
      overflowY={"scroll"}
      overflowX={"hidden"}
      height={"100%"}
    >
      <Header
        leftButton={() => {
          // TODO: pass in a navigate flag if a profile was created
          if (backPath) {
            navigate(backPath);
            return;
          }
          if (isNew === false && isAssociated === false) {
            navigate("/select");
          } else {
            resetState();
            navigate("/");
          }
        }}
        leftButtonIcon={
          (searchData && searchData.matches.length > 0) || backPath
            ? faChevronLeft
            : faHome
        }
      />
      {isAssociated === false ? (
        <ProfileNewInquiry
          profileData={profileData}
          navigate={navigate}
          associateProfile={associateProfile}
        />
      ) : (
        <ProfileRatingBody
          profileData={profileData}
          isNew={isNew}
          reviewData={reviewData}
          resetState={resetState}
          navigate={navigate}
          onReview={onReview}
        />
      )}
    </Flex>
  );
}
