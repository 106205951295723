import { Box, Divider, Flex, Icon, VStack, Text } from "@chakra-ui/react";
import {
  faCalendar,
  faCalendarWeek,
  faChartLine,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProfileResponse } from "../../types/image-flow";

function ActivityCard({
  label,
  value,
  icon,
  color,
}: {
  label: string;
  value: number | string;
  icon: IconDefinition;
  color: string;
}) {
  return (
    <Flex
      width="100%"
      py={6}
      px={4}
      justifyContent="space-between"
      alignItems="center"
      transition="all 0.3s"
      _hover={{ bg: `${color}10` }}
    >
      <Flex alignItems="center">
        <Icon
          as={FontAwesomeIcon}
          icon={icon}
          color={color}
          fontSize="2xl"
          mr={4}
        />
        <Text fontSize="lg" fontWeight="medium" color="gray.700">
          {label}
        </Text>
      </Flex>
      <Text fontSize="2xl" fontWeight="bold" color={color}>
        {value}
      </Text>
    </Flex>
  );
}

export function ProfileActivity({
  profileData,
}: {
  profileData: ProfileResponse;
}) {
  const { reviewData } = profileData;
  const dates =
    reviewData?.dates?.map((date: any) => ({
      date: date.date,
      count: parseInt(date.cnt),
    })) ?? [];

  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  const thisMonth = dates.filter(
    (d: any) => new Date(d.date) >= thirtyDaysAgo
  ).length;
  const thisWeek = dates.filter(
    (d: any) => new Date(d.date) >= sevenDaysAgo
  ).length;

  const avgPerWeek = (dates.length / 4).toFixed(1);

  return (
    <Box width="100%" bg="white" borderRadius="3xl" overflow="hidden">
      <VStack spacing={0} divider={<Divider />}>
        <ActivityCard
          label="This Month"
          value={thisMonth}
          icon={faCalendar}
          color="pink.400"
        />
        <ActivityCard
          label="This Week"
          value={thisWeek}
          icon={faCalendarWeek}
          color="purple.400"
        />
        <ActivityCard
          label="Weekly Average"
          value={avgPerWeek}
          icon={faChartLine}
          color="blue.400"
        />
      </VStack>
    </Box>
  );
}
