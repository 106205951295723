import { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  IconButton,
  useColorModeValue,
  Icon,
  Heading,
  Avatar,
  Collapse,
  Image,
  Tag,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { AnimatePresence, motion } from "framer-motion";
import { useProfile } from "../../providers/use-profile";
import { Topics } from "./Topics";
import { Catfish } from "./Catfish";
import { DateType } from "./DateType";
import { StarSign } from "./StarSign";
import { Dealbreakers } from "./Dealbreakers";
import { Sex } from "./Sex";
import { Flags } from "./Flags";
import { SecondDate } from "./SecondDate";
import { ProfileResponse } from "../../types/image-flow";
import { Meeting, MEETING_DATA } from "./Meeting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReviewRef } from "../../providers/use-review";
import { ReviewRanking } from "./ReviewRanking";
import { FLAG_CATEGORIES, TILE_CATEGORIES } from "../../data/review";
import { createTheme, Rating, ThemeProvider } from "@mui/material";

const MotionBox = motion(Box);

const theme = createTheme();

function ModalHeader({
  profileData,
  onClose,
  textColor,
  accentColor,
}: {
  profileData?: ProfileResponse;
  onClose: () => void;
  textColor: string;
  accentColor: string;
}) {
  return (
    <Flex
      justify="space-between"
      alignItems={"center"}
      px={6}
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <Flex
        fontSize="lg"
        fontWeight="bold"
        color={textColor}
        alignItems={"center"}
        flexDirection={"row"}
        gap={2}
      >
        <Avatar src={profileData?.imgUrls[0]} size={"sm"} />
        <Text>
          {profileData?.name
            ? profileData.name.charAt(0).toUpperCase() +
              profileData.name.slice(1)
            : "Profile"}
        </Text>
      </Flex>
      <IconButton
        aria-label="Close"
        icon={<CloseIcon />}
        onClick={onClose}
        variant="ghost"
        size="lg"
        color={accentColor}
      />
    </Flex>
  );
}

function SlideInWindow({
  expandedOption,
  setExpandedOption,
  bgColor,
  textColor,
  accentColor,
  hoverColor,
  ratingOptions,
}: {
  expandedOption: number | null;
  setExpandedOption: (value: number | null) => void;
  bgColor: string;
  textColor: string;
  accentColor: string;
  hoverColor: string;
  ratingOptions: {
    prettyName: string;
    icon: string;
    component: React.ReactNode;
  }[];
}) {
  return (
    <MotionBox
      position="absolute"
      top={0}
      right={0}
      width="100%"
      height="100%"
      bg={bgColor}
      initial={{ x: "100%" }}
      animate={{ x: expandedOption !== null ? "0%" : "100%" }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      <AnimatePresence>
        {expandedOption !== null && (
          <motion.div
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            style={{ height: "100%" }}
          >
            <Flex direction="column" h="100%">
              <Flex
                justify="space-between"
                alignContent={"center"}
                align="center"
                px={4}
                py={3}
                borderBottom="1px solid"
                borderColor="gray.200"
                bg={bgColor}
                position="sticky"
                top={0}
                zIndex={1}
                h="10%"
              >
                <IconButton
                  aria-label="Back"
                  icon={<ChevronLeftIcon boxSize={8} />}
                  onClick={() => setExpandedOption(null)}
                  variant="ghost"
                  size="xl"
                  color={accentColor}
                  _active={{ bg: hoverColor }}
                />
                <Heading fontSize="xl" fontWeight="semibold" color={textColor}>
                  {ratingOptions[expandedOption].prettyName}
                </Heading>
                <Box width={8} /> {/* Spacer for alignment */}
              </Flex>
              <Flex h="90%">{ratingOptions[expandedOption].component}</Flex>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </MotionBox>
  );
}

function CollapseableSection({
  children,
  isOpen,
  title,
  rightElement,
  onToggle,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  title: string;
  onToggle: () => void;
  rightElement?: React.ReactNode;
}) {
  return (
    <Flex direction="column">
      <Flex
        padding={2}
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={onToggle}
      >
        <Text fontWeight="semibold">{title}</Text>
        <Flex>
          {rightElement}
          <Icon
            as={isOpen ? ChevronUpIcon : ChevronDownIcon}
            w={6}
            h={6}
            transition="transform 0.2s"
            transform={isOpen ? "rotate(0deg)" : "rotate(0deg)"}
          />
        </Flex>
      </Flex>
      <Collapse in={isOpen}>{children}</Collapse>
    </Flex>
  );
}

export function ReviewModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { submitReview, comparisonData, reviewData } = useReviewRef();

  const [expandedOption, setExpandedOption] = useState<number | null>(null);
  const [secondDate, setSecondDate] = useState<"yes" | "maybe" | "no" | null>(
    null
  );
  const [meeting, setMeeting] = useState<string | null>(null);
  const { profileData } = useProfile();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const accentColor = useColorModeValue("pink.500", "pink.300");
  const hoverColor = useColorModeValue("gray.100", "gray.700");
  const [isSecondDateOpen, setIsSecondDateOpen] = useState(true);
  const [isMeetingOpen, setIsMeetingOpen] = useState(false);

  const meetingData = MEETING_DATA.find((m) => m.systemName === meeting);

  const isRanking = comparisonData && !!comparisonData.profileIdToCompare;

  const ratingOptions = useMemo(
    () => [
      {
        prettyName: "Date Type",
        icon: "🍽️",
        component: <DateType onClose={() => setExpandedOption(null)} />,
        emojis: [
          TILE_CATEGORIES.DATE_TYPE.options.find(
            (option) => option.systemName === reviewData.dropdown.DATE_TYPE
          )?.emoji,
        ].filter((emoji) => emoji),
      },
      {
        prettyName: "Flags",
        icon: "🚩",
        component: <Flags />,
        emojis: FLAG_CATEGORIES.GREEN_FLAGS.options
          .filter((option) =>
            reviewData.flags.GREEN_FLAGS?.includes(option.systemName)
          )
          .concat(
            FLAG_CATEGORIES.RED_FLAGS.options.filter((option) =>
              reviewData.flags.RED_FLAGS?.includes(option.systemName)
            )
          )
          .map((option) => option.emoji),
      },
      {
        prettyName: "Topics",
        icon: "💬",
        component: <Topics />,
        emojis: FLAG_CATEGORIES.TOPICS.options
          .filter((option) =>
            reviewData.flags.TOPICS?.includes(option.systemName)
          )
          .map((option) => option.emoji),
      },
      {
        prettyName: "Dealbreakers",
        icon: "🚫",
        component: <Dealbreakers />,
        emojis: FLAG_CATEGORIES.DEALBREAKERS.options
          .filter((option) =>
            reviewData.flags.DEALBREAKERS?.includes(option.systemName)
          )
          .map((option) => option.emoji),
      },
      {
        prettyName: "Catfish",
        icon: "🐡",
        component: <Catfish onClose={() => setExpandedOption(null)} />,
        stars: reviewData.ratings.catfish,
      },
      {
        prettyName: "Star Sign",
        icon: "♈",
        component: <StarSign onClose={() => setExpandedOption(null)} />,
        emojis: [
          TILE_CATEGORIES.STAR_SIGN.options.find(
            (option) => option.systemName === reviewData.dropdown.STAR_SIGN
          )?.emoji,
        ].filter((emoji) => emoji),
      },
      {
        prettyName: "Sex",
        icon: "🔥",
        component: <Sex />,
        stars:
          ((reviewData.ratings.kinkiness ?? 0) +
            (reviewData.ratings.bedroom ?? 0)) /
            ((reviewData.ratings.kinkiness !== null ? 1 : 0) +
              (reviewData.ratings.bedroom !== null ? 1 : 0)) || 1,
      },
    ],
    [setExpandedOption, reviewData]
  );

  console.log(ratingOptions);

  if (!isOpen) return null;

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.8)"
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MotionBox
        bg={isRanking ? "transparent" : bgColor}
        borderRadius="2xl"
        w="90%"
        maxW="600px"
        overflow="hidden"
        position="relative"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
      >
        <Flex direction="column" h="100%">
          <Collapse in={isRanking}>
            <ReviewRanking callback={onClose} />
          </Collapse>
          <Collapse in={!isRanking}>
            <ModalHeader
              profileData={profileData}
              onClose={onClose}
              textColor={textColor}
              accentColor={accentColor}
            />

            <CollapseableSection
              isOpen={isSecondDateOpen}
              title="Second Date"
              onToggle={() => {
                setIsSecondDateOpen(!isSecondDateOpen);
              }}
              rightElement={
                secondDate !== null && (
                  <Tag
                    size="md"
                    bg={
                      secondDate === "yes"
                        ? "green.300"
                        : secondDate === "maybe"
                        ? "yellow.300"
                        : "red.300"
                    }
                    color="white"
                  >
                    {secondDate === "yes"
                      ? "Yes"
                      : secondDate === "maybe"
                      ? "Maybe"
                      : "No"}
                  </Tag>
                )
              }
            >
              <SecondDate
                secondDate={secondDate}
                setSecondDate={(val) => {
                  setSecondDate(val);
                  setIsSecondDateOpen(false);
                  if (!meeting) {
                    setIsMeetingOpen(true);
                  }
                }}
              />
            </CollapseableSection>

            {secondDate !== null && (
              <CollapseableSection
                isOpen={isMeetingOpen}
                title="Meeting"
                onToggle={() => {
                  setIsMeetingOpen(!isMeetingOpen);
                }}
                rightElement={
                  meeting !== null && (
                    <Tag
                      size="md"
                      bg={meetingData?.bg}
                      color={meetingData?.color}
                      gap={1}
                      display="flex"
                      alignItems="center"
                    >
                      {meetingData?.img && (
                        <Image src={meetingData.img} w={3} />
                      )}
                      {meetingData?.icon && (
                        <FontAwesomeIcon icon={meetingData.icon} />
                      )}
                      {meetingData?.emoji && <Text>{meetingData.emoji}</Text>}
                      {meetingData?.prettyName && (
                        <Text>{meetingData.prettyName}</Text>
                      )}
                    </Tag>
                  )
                }
              >
                <Meeting
                  meeting={meeting}
                  setMeeting={(val) => {
                    setMeeting(val);
                    setIsMeetingOpen(false);
                  }}
                />
              </CollapseableSection>
            )}

            <Collapse in={secondDate !== null && meeting !== null}>
              <Flex
                flexGrow={1}
                overflowY="auto"
                direction={"column"}
                justifyContent={"space-between"}
              >
                <VStack
                  spacing={0}
                  align="stretch"
                  w="100%"
                  divider={
                    <Box
                      borderBottom="1px solid"
                      borderColor="gray.200"
                      w="100%"
                    />
                  }
                >
                  {ratingOptions.map((category, key) => (
                    <Box key={key}>
                      <Flex
                        onClick={() => {
                          setExpandedOption(key);
                        }}
                        width="100%"
                        justifyContent="space-between"
                        fontWeight="regular"
                        py={2}
                        px={4}
                        cursor="pointer"
                        _active={{ bg: hoverColor }}
                      >
                        <Flex alignItems="center" color="gray.700">
                          <Box width="24px" textAlign="center" mr={3}>
                            {category.icon}
                          </Box>
                          <Text>{category.prettyName}</Text>
                        </Flex>
                        <Flex gap={1}>
                          {category.emojis && category.emojis.length > 0 && (
                            <Flex
                              backgroundColor="gray.100"
                              alignItems="center"
                              color="gray.400"
                              px={2}
                              borderRadius={4}
                              maxW="100px"
                            >
                              <Text
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {category.emojis.join(" ")}
                              </Text>
                            </Flex>
                          )}
                          {!!category.stars && (
                            <Flex
                              backgroundColor="gray.100"
                              alignItems="center"
                              color="gray.400"
                              px={2}
                              borderRadius={4}
                              maxW="100px"
                            >
                              <ThemeProvider theme={theme}>
                                <Rating
                                  value={category.stars}
                                  precision={0.5}
                                  readOnly
                                  size="small"
                                />
                              </ThemeProvider>
                            </Flex>
                          )}
                          <Icon
                            as={ChevronRightIcon}
                            fontSize="1.5rem"
                            color={"gray.400"}
                            transition="transform 0.2s"
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  ))}
                </VStack>
                <Flex
                  width="100%"
                  bg="pink.500"
                  color="white"
                  justifyContent="center"
                  alignItems="center"
                  height="48px"
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() => {
                    submitReview({
                      reviewData,
                      closeCallback: onClose,
                    });
                  }}
                >
                  Done
                </Flex>
              </Flex>
            </Collapse>
          </Collapse>
        </Flex>
        <SlideInWindow
          expandedOption={expandedOption}
          setExpandedOption={setExpandedOption}
          bgColor={bgColor}
          textColor={textColor}
          accentColor={accentColor}
          hoverColor={hoverColor}
          ratingOptions={ratingOptions}
        />
      </MotionBox>
    </Box>
  );
}
