import { Flex, Image, Text } from "@chakra-ui/react";
import { Header } from "../common/Header";
import { DateRateProvider, useDateRate } from "../../providers/use-daterate";
import { useNavigate } from "react-router-dom";
import { SearchButton } from "../common/SearchButton";

function PastDateCard({
  imgUrls,
  profileId,
  viewedAt,
  name,
}: {
  imgUrls: string[];
  profileId: string;
  viewedAt: string;
  name: string;
}) {
  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      width="100%"
      maxWidth="300px"
      height="auto"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      transition="all 0.3s ease"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
      }}
      onClick={() =>
        navigate(`/profile/${profileId}`, {
          state: { backPath: "/history" },
        })
      }
      cursor="pointer"
    >
      <Image
        src={imgUrls[0]}
        alt={`${name}'s profile`}
        width="100%"
        aspectRatio="1/1"
        objectFit="cover"
      />
      <Flex justify="space-between" align="center" padding="3" bg="white">
        <Flex direction="column">
          <Text fontSize="sm" fontWeight="bold" color="gray.800" isTruncated>
            {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {new Date(viewedAt).toLocaleDateString()}
          </Text>
        </Flex>
        <Text
          fontSize="xs"
          fontWeight="medium"
          color="pink.500"
          _hover={{ textDecoration: "underline" }}
        >
          View
        </Text>
      </Flex>
    </Flex>
  );
}

function PastDateGrid() {
  const { viewedProfilesQuery } = useDateRate();

  return (
    <Flex
      flex={1}
      overflowY={"scroll"}
      width="100%"
      height="100%"
      paddingBottom={10}
      flexDirection="column"
      alignItems="center"
      gap="3"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(0,0,0,0.2)",
          borderRadius: "24px",
        },
      }}
    >
      <Flex
        width="100%"
        maxWidth="600px"
        flexWrap="wrap"
        justifyContent="space-between"
        gap="3"
      >
        {viewedProfilesQuery?.data?.map((pastDate, index) => (
          <Flex
            key={pastDate.profileId}
            width="calc(50% - 6px)"
            justifyContent="center"
          >
            <PastDateCard {...pastDate} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export function PastDatesInternal() {
  const navigate = useNavigate();
  return (
    <Flex
      height={"100dvh"}
      width={"100vw"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      padding={10}
      bgGradient="linear(to-b, white, pink.100)"
    >
      <Header leftText="Recent Views" />
      <PastDateGrid />
      <SearchButton onClick={() => navigate("/")} />
    </Flex>
  );
}

export function PastDates() {
  return (
    <DateRateProvider>
      <PastDatesInternal />
    </DateRateProvider>
  );
}
