import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ProfileIcon } from "./ProfileIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAuthRef } from "../../providers/use-auth";
import { useNavigate } from "react-router-dom";
import { useDateRate } from "../../providers/use-daterate";
import { SettingsModal } from "./SettingsModal";

function ReviewViewsRemaining() {
  const [modalOpen, setModalOpen] = useState(false);
  const { userDetailsQuery } = useDateRate();
  const viewsRemaining =
    userDetailsQuery?.data?.metadata?.viewData?.remainingViews;

  if (viewsRemaining === undefined) {
    return null;
  }

  return (
    <>
      <Flex
        alignItems="center"
        bg="rgba(255, 105, 180, 0.1)"
        borderRadius="full"
        padding="2px 8px"
        gap={2}
        onClick={() => setModalOpen(true)}
      >
        <Flex alignItems="center">
          <Text fontSize="lg">👀</Text>
          <Text color="pink.400" fontWeight="bold" fontSize="lg" marginLeft={1}>
            {viewsRemaining}
          </Text>
        </Flex>
        <Flex alignItems="flex-start">
          <FontAwesomeIcon
            icon={faInfoCircle}
            color="rgba(0, 0, 0, 0.25)"
            size="xs"
            style={{ marginLeft: "4px", cursor: "pointer" }}
          />
        </Flex>
      </Flex>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="xl" mx={4}>
          <ModalHeader fontSize="xl" fontWeight="bold" textAlign="center">
            profile views
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text fontSize="md" textAlign="center">
                you have <strong>{viewsRemaining}</strong> profile views
                remaining.
              </Text>
              <Text fontSize="md" textAlign="center">
                to get more views, you can leave a rating on a past date.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              color="white"
              bg="pink.200"
              w="full"
              onClick={() => setModalOpen(false)}
            >
              okay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function LoginButton() {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate("/login")}
      bg="pink.300"
      color="white"
      border="1px solid pink.500"
      borderRadius="full"
    >
      sign in
    </Button>
  );
}

export function Header({
  leftText,
  leftButton,
  leftButtonIcon,
  centerElement,
}: {
  leftText?: string;
  leftButton?: () => void;
  leftButtonIcon?: IconProp;
  centerElement?: React.ReactNode;
}) {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const { jwt } = useAuthRef();

  return (
    <Flex flexDirection={"column"}>
      <Flex
        width="100vw"
        height="50px"
        justifyContent="space-between"
        alignItems={"center"}
        paddingLeft={5}
        paddingRight={5}
      >
        <SettingsModal
          userModalOpen={userModalOpen}
          setUserModalOpen={setUserModalOpen}
        />
        <Flex
          flex={1}
          justifyContent="flex-start"
          alignItems="center"
          onClick={leftButton}
          ml={2}
        >
          {leftButtonIcon && (
            <IconButton
              aria-label="Back"
              icon={<FontAwesomeIcon icon={leftButtonIcon} />}
              variant="ghost"
              color="gray.400"
              size="lg"
              fontSize="24px"
              _hover={{ bg: "gray.100" }}
              _active={{ bg: "gray.200" }}
            />
          )}
          {leftText && (
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="pink.500"
              whiteSpace="nowrap"
            >
              {leftText}
            </Text>
          )}
        </Flex>
        <Flex flex={1} justifyContent={"flex-end"} gap={5}>
          <ReviewViewsRemaining />
          <Flex
            onClick={() => setUserModalOpen(true)}
            alignItems={"center"}
            justifyContent={"flex-end"}
            cursor="pointer"
            color="#F687B3"
            _hover={{ color: "#E9558F" }}
          >
            {jwt && <ProfileIcon />}
            {!jwt && <LoginButton />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
