import { Flex } from "@chakra-ui/react";
import { MultiSelect } from "./MultiSelect";
import { useReviewRef } from "../../providers/use-review";
import { FLAG_CATEGORIES } from "../../data/review";

export function Dealbreakers() {
  const { reviewData, setReviewData } = useReviewRef();

  const dealbreakers = FLAG_CATEGORIES.DEALBREAKERS.options.map(
    (dealbreaker) => ({
      key: dealbreaker.systemName,
      value: dealbreaker.prettyName,
      icon: dealbreaker.emoji!,
    })
  );

  const handleDealbreakersChange = (selected: string[]) => {
    setReviewData({
      ...reviewData,
      flags: {
        ...reviewData.flags,
        DEALBREAKERS: selected,
      },
    });
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <MultiSelect
        selected={reviewData.flags.DEALBREAKERS ?? []}
        options={dealbreakers}
        onChange={handleDealbreakersChange}
      />
    </Flex>
  );
}
