import { Button, Flex, Text } from "@chakra-ui/react";
import { useReviewRef } from "../../providers/use-review";

export function SecondDate({
  secondDate,
  setSecondDate,
}: {
  secondDate: "yes" | "maybe" | "no" | null;
  setSecondDate: (value: "yes" | "maybe" | "no" | null) => void;
}) {
  const { reviewData, setReviewData } = useReviewRef();

  const handleSetSecondDate = (value: "yes" | "maybe" | "no" | null) => {
    setSecondDate(value);
    if (value === null) return;
    setReviewData({
      ...reviewData,
      dropdown: { ...reviewData.dropdown, SECOND_DATE: value },
    });
  };

  console.log(reviewData);

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      py={6}
      px={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      bg="gray.50"
    >
      <Text fontSize="xl" fontWeight="semibold" mb={4} color="gray.800">
        Would you go on a second date?
      </Text>
      <Flex width="100%" justify="space-between" maxWidth="320px">
        <Button
          bg={
            secondDate === null
              ? "#5cbc73"
              : secondDate === "yes"
              ? "#5cbc73"
              : "gray.400"
          }
          color={"white"}
          size="lg"
          flex={1}
          mr={3}
          borderRadius="full"
          _hover={{ bg: "#5cbc73" }}
          _active={{ transform: "translateY(-2px)", boxShadow: "lg" }}
          transition="all 0.2s"
          onClick={() => handleSetSecondDate("yes")}
        >
          Yes
        </Button>
        <Button
          bg={
            secondDate === null
              ? "#e1b871"
              : secondDate === "maybe"
              ? "#e1b871"
              : "gray.400"
          }
          size="lg"
          flex={1}
          mx={3}
          color={"white"}
          borderRadius="full"
          _hover={{ bg: "#e1b871" }}
          _active={{ transform: "translateY(-2px)", boxShadow: "lg" }}
          transition="all 0.2s"
          onClick={() => handleSetSecondDate("maybe")}
        >
          Maybe
        </Button>
        <Button
          bg={
            secondDate === null
              ? "red.400"
              : secondDate === "no"
              ? "red.400"
              : "gray.400"
          }
          color={"white"}
          size="lg"
          flex={1}
          ml={3}
          borderRadius="full"
          _hover={{ bg: "red.400" }}
          _active={{ transform: "translateY(-2px)", boxShadow: "lg" }}
          transition="all 0.2s"
          onClick={() => handleSetSecondDate("no")}
        >
          No
        </Button>
      </Flex>
    </Flex>
  );
}
