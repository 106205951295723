import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function SloganHeader() {
  return (
    <Flex
      w="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={10}
      px={4}
      position="relative"
    >
      <img src="/logo_pink.png" alt="logo" width={100} height={100} />
      <Flex flexDirection="column" alignItems="center" mt={6}>
        <Text
          fontSize={["xl", "2xl", "3xl"]}
          fontWeight="bold"
          color="gray.700"
          textAlign="center"
          lineHeight="shorter"
          display={"flex"}
          flexDirection={"column"}
        >
          {/* <Text
            as="span"
            color="gray.500"
            fontWeight="extrabold"
            fontSize={"1rem"}
          >
            screen your date
          </Text>
          <Text
            as="span"
            color="gray.700"
            fontWeight="extrabold"
            fontSize={"1.5rem"}
          >
            meet your date
          </Text> */}
          {/* <Text
            as="span"
            color="#FF69B4"
            fontWeight="extrabold"
            fontSize={"2rem"}
          >
            the beli for dating
          </Text> */}
          <Text
            as="span"
            color="#ffb2cf"
            fontWeight="extrabold"
            fontSize={"1.5rem"}
          >
            The Beli for Dating
          </Text>
        </Text>
      </Flex>
      {/* <Text
        fontSize={["sm", "md"]}
        color="gray.600"
        mt={6}
        textAlign="center"
        maxWidth="300px"
        fontWeight="medium"
        lineHeight="tall"
      >
        The Beli for dating.
      </Text> */}
    </Flex>
  );
}
