import { Box, Flex, Text } from "@chakra-ui/react";
import { SearchBar } from "./SearchBar";
import { ReactNode, useMemo, useState } from "react";

function SelectRow({
  key,
  value,
  selected,
  icon,
  onClick,
}: {
  key: string;
  value: string;
  selected: boolean;
  icon: ReactNode;
  onClick: () => void;
}) {
  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      gap={2}
      p={2}
      borderBottom="1px solid"
      borderColor="gray.200"
      onClick={onClick}
      _active={{
        bg: "gray.100",
      }}
    >
      <Flex align="center" gap={2}>
        <Text fontSize="sm" fontWeight="regular">
          {icon}
        </Text>
        <Text fontSize="sm" fontWeight="regular">
          {value}
        </Text>
      </Flex>
      {selected && <Box w="16px" h="16px" bg="pink.500" borderRadius="sm" />}
    </Flex>
  );
}

export function Select({
  options,
  selected,
  onChange,
}: {
  options: { key: string; value: string; icon?: ReactNode }[];
  selected: string | null;
  onChange: (selected: string | null) => void;
}) {
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.value.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, options]
  );

  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      align="center"
      justify="space-between"
    >
      <SearchBar value={searchValue} onValueChange={setSearchValue} />
      <Flex
        direction="column"
        w="100%"
        flex={1}
        align="center"
        justify="space-between"
        overflowY={"scroll"}
      >
        {filteredOptions.map((option) => (
          <SelectRow
            key={option.key}
            value={option.value}
            icon={option.icon}
            selected={selected === option.key}
            onClick={() => {
              if (selected === option.key) {
                onChange(null);
              } else {
                onChange(option.key);
              }
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
}
