import { Flex, Text, Checkbox } from "@chakra-ui/react";
import { SearchBar } from "./SearchBar";
import { useMemo, useState } from "react";

function SelectRow({
  key,
  value,
  icon,
  selected,
  onClick,
}: {
  key: string;
  value: string;
  selected: boolean;
  icon?: string;
  onClick: () => void;
}) {
  return (
    <Flex
      w="100%"
      h="100%"
      align="center"
      justify="space-between"
      gap={2}
      p={2}
      color="gray.800"
      borderBottom="1px solid"
      borderColor="gray.200"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      _active={{
        bg: "gray.100",
      }}
    >
      <Flex align="center" justify="flex-start" gap={2}>
        {icon && (
          <Text fontSize="sm" fontWeight="regular">
            {icon}
          </Text>
        )}
        <Text fontSize="sm" fontWeight="regular">
          {value}
        </Text>
      </Flex>
      <Checkbox
        colorScheme="pink"
        size="lg"
        isChecked={selected}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClick();
        }}
      />
    </Flex>
  );
}

export function MultiSelect({
  selected,
  options,
  onChange,
}: {
  selected: string[];
  options: { key: string; value: string; icon: string }[];
  onChange: (selected: string[]) => void;
}) {
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.value.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, options]
  );

  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      flex={1}
      align="center"
      justify="space-between"
    >
      <SearchBar value={searchValue} onValueChange={setSearchValue} />
      <Flex
        direction="column"
        w="100%"
        flex={1}
        align="center"
        justify="flex-start"
        overflowY={"scroll"}
      >
        {filteredOptions.map((option) => (
          <SelectRow
            key={option.key}
            value={option.value}
            icon={option.icon}
            selected={selected.includes(option.key)}
            onClick={() => {
              console.log("clicky");
              console.log(option.key);
              console.log(selected);
              if (selected.includes(option.key)) {
                console.log("removing");
                onChange(selected.filter((key) => key !== option.key));
              } else {
                console.log("adding");
                onChange([...selected, option.key]);
              }
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
}
