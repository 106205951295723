import { useState } from "react";
import {
  Flex,
  Text,
  Image,
  VStack,
  Heading,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useReviewRef } from "../../providers/use-review";
import { useProfile } from "../../providers/use-profile";

export function ReviewRanking({ callback }: { callback: () => void }) {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const { profileData } = useProfile();
  const { mutateComparison, comparisonData } = useReviewRef();

  const handleDateSelection = (profileId?: string | null) => {
    if (!profileId) return;
    setSelectedDate(profileId);
    mutateComparison?.mutate({
      profileId: profileId,
      callback: () => {
        setSelectedDate(null);
        callback();
      },
    });
  };

  const currentDate = {
    name: profileData?.name
      ? profileData.name.charAt(0).toUpperCase() + profileData.name.slice(1)
      : undefined,
    profileId: profileData?.profileId,
    image: profileData?.imgUrls?.[0],
  };

  const pastDate = {
    name: comparisonData?.name
      ? comparisonData.name.charAt(0).toUpperCase() +
        comparisonData.name.slice(1)
      : undefined,
    profileId: comparisonData?.profileIdToCompare,
    image: comparisonData?.imgUrls[0],
  };

  if (mutateComparison?.isPending) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="100vw"
        px={4}
        bg="gray.50"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      px={4}
    >
      <VStack spacing={2} width="100%" maxWidth="400px">
        <Heading
          bg={"white"}
          borderRadius={"xl"}
          p={4}
          py={2}
          w={"100%"}
          size="sm"
          textAlign="left"
          color="gray.800"
        >
          <Text>Date Comparison</Text>
          <Text fontSize={"xs"} fontWeight={"normal"} color={"gray.500"}>
            Who was a better date?
          </Text>
        </Heading>
        {[currentDate, pastDate].map((date, index) => (
          <Box
            key={date.profileId}
            as="button"
            onClick={() => handleDateSelection(date.profileId)}
            width="100%"
            borderWidth={2}
            borderColor={
              selectedDate === date.profileId ? "pink.500" : "gray.200"
            }
            borderRadius="xl"
            overflow="hidden"
            transition="all 0.2s"
            _hover={{ borderColor: "pink.300" }}
            _active={{ transform: "scale(0.98)" }}
            bg="white"
            boxShadow="sm"
          >
            <Flex alignItems="center" p={3}>
              <Image
                src={date.image}
                alt={date.name}
                boxSize="70px"
                objectFit="cover"
                borderRadius="full"
                mr={4}
                border="2px solid"
                borderColor="pink.100"
              />
              <Text fontWeight="semibold" fontSize="lg" color="gray.800">
                {date.name}
              </Text>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
}
