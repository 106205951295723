import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

export function SearchBar({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (value: string) => void;
}) {
  const handleClear = () => {
    onValueChange("");
  };

  return (
    <Flex flex={0} w="100%" align="center">
      <Flex w="100%" p={2} borderRadius="md">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search"
            bg="gray.100"
            pl="40px"
            value={value}
            onChange={(e) => onValueChange(e.target.value)}
          />
          {value && (
            <InputRightElement>
              <IconButton
                aria-label="Clear search"
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                onClick={handleClear}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Flex>
    </Flex>
  );
}
