import { Flex } from "@chakra-ui/react";
import { Select } from "./Select";
import { useReviewRef } from "../../providers/use-review";
import { TILE_CATEGORIES } from "../../data/review";

export function StarSign({ onClose }: { onClose: () => void }) {
  const { reviewData, setReviewData } = useReviewRef();

  const handleStarSignChange = (sign: string | null) => {
    if (!sign) return;

    onClose();
    setReviewData({
      ...reviewData,
      dropdown: {
        ...reviewData.dropdown,
        STAR_SIGN: sign,
      },
    });
  };

  const starSigns = TILE_CATEGORIES.STAR_SIGN.options.map((sign) => ({
    key: sign.systemName,
    value: sign.prettyName,
    icon: sign.emoji!,
  }));

  return (
    <Flex flexDirection="column" flex={1}>
      <Select
        options={starSigns}
        selected={reviewData.dropdown.STAR_SIGN}
        onChange={handleStarSignChange}
      />
    </Flex>
  );
}
