import {
  Card,
  Text,
  Badge,
  Flex,
  Image,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { ProfileResponse } from "../../types/image-flow";
import { faBan, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IMAGES: Record<string, React.ReactNode> = {
  tinder: (
    <Badge
      display="flex"
      alignItems="center"
      gap={2}
      bg="pink.100"
      color="pink.500"
      py={2}
      px={3}
      borderRadius="full"
      fontWeight="medium"
      boxShadow="sm"
      _hover={{ transform: "translateY(-1px)", boxShadow: "md" }}
      transition="all 0.2s"
    >
      <Image src="/tinder.png" boxSize="1.2em" objectFit="contain" />
      Tinder
    </Badge>
  ),
  bumble: (
    <Badge
      display="flex"
      alignItems="center"
      gap={2}
      bg="yellow.100"
      color="yellow.600"
      py={2}
      px={3}
      borderRadius="full"
      fontWeight="medium"
      boxShadow="sm"
      _hover={{ transform: "translateY(-1px)", boxShadow: "md" }}
      transition="all 0.2s"
    >
      <Image src="/bumble.png" boxSize="1.2em" objectFit="contain" />
      Bumble
    </Badge>
  ),
  hinge: (
    <Badge
      display="flex"
      alignItems="center"
      gap={2}
      bg="gray.100"
      color="gray.600"
      py={2}
      px={3}
      borderRadius="full"
      fontWeight="medium"
      boxShadow="sm"
      _hover={{ transform: "translateY(-1px)", boxShadow: "md" }}
      transition="all 0.2s"
    >
      <Image src="/hinge.png" boxSize="1.2em" objectFit="contain" />
      Hinge
    </Badge>
  ),
};

export function ActiveOn({ profileData }: { profileData: ProfileResponse }) {
  const { reviewData } = profileData;

  const activeOn = reviewData?.dropdown?.MEET_LOCATION?.map(({ name }) => name);

  return (
    <Flex flexDirection="row" gap={3} flexWrap="wrap" justifyContent="center">
      {activeOn?.map((app) => IMAGES[app])}
      {(!activeOn || activeOn.length === 0) && (
        <Tooltip label="No active platforms reported" placement="top">
          <Icon
            as={FontAwesomeIcon}
            icon={faBan}
            boxSize="1.5rem"
            color="gray.400"
            opacity={0.5}
            p={2}
            borderRadius="full"
            _hover={{
              color: "gray.600",
              bg: "gray.200",
              transform: "scale(1.1)",
            }}
            transition="all 0.2s"
          />
        </Tooltip>
      )}
    </Flex>
  );
}
