import { Home } from "./Home";
import { Login } from "./Login";
import { Select } from "./Select";
import { Profile } from "./Profile";
import { PastDates } from "./PastDates";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DateRankings } from "./DateRankings";
import { LandingPage } from "./Landing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/select",
    element: <Select />,
  },
  {
    path: "/profile/:profileId",
    element: <Profile />,
  },
  {
    path: "/history",
    element: <PastDates />,
  },
  {
    path: "/rankings",
    element: <DateRankings />,
  },
  {
    path: "/landing",
    element: <LandingPage />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
