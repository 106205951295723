import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Container,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUserShield,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);

const Section = ({ children, ...props }: { children: React.ReactNode }) => (
  <Flex
    as="section"
    minHeight="100dvh"
    scrollSnapAlign="start"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {children}
  </Flex>
);

const FeatureCard = ({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => (
  <MotionBox
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    p={8}
    shadow="2xl"
    borderRadius="xl"
    bg={useColorModeValue("white", "gray.800")}
    _hover={{ transform: "translateY(-5px)", transition: "all 0.3s ease" }}
  >
    <Icon
      as={FontAwesomeIcon}
      icon={icon}
      w={12}
      h={12}
      color="pink.500"
      mb={6}
    />
    <Heading fontSize="2xl" mb={4}>
      {title}
    </Heading>
    <Text color={useColorModeValue("gray.600", "gray.300")} fontSize="lg">
      {description}
    </Text>
  </MotionBox>
);

export const LandingPage = () => {
  const bgGradient = useColorModeValue(
    "linear(to-b, white, pink.50)",
    "linear(to-b, gray.900, pink.900)"
  );

  const navigate = useNavigate();

  return (
    <Box bgGradient={bgGradient} minH="100dvh" overflowX="hidden">
      <Section>
        <Container maxW="container.xl">
          <MotionFlex
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            minHeight="100dvh"
          >
            <MotionBox
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Image
                src="/logo_pink.png"
                alt="Rate Your Date Logo"
                w={40}
                h={40}
                mb={12}
              />
            </MotionBox>

            <MotionHeading
              as="h1"
              fontSize={{ base: "5xl", md: "6xl", lg: "7xl" }}
              fontWeight="extrabold"
              mb={6}
              bgGradient="linear(to-r, pink.400, purple.600)"
              bgClip="text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              Rate Your Date
            </MotionHeading>

            <MotionText
              fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
              mb={12}
              color={useColorModeValue("gray.600", "gray.300")}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              Empowering Women with AI-Driven Dating Insights
            </MotionText>

            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9, duration: 0.8 }}
            >
              <Button
                as="a"
                size="lg"
                colorScheme="pink"
                fontWeight="bold"
                rounded="full"
                px={10}
                py={6}
                fontSize="xl"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "xl",
                  bg: "purple.500",
                }}
                transition="all 0.3s ease"
                onClick={() => navigate("/")}
              >
                Get Started
              </Button>
            </MotionBox>
          </MotionFlex>
        </Container>
      </Section>

      <Section>
        <Container maxW="container.xl" py={20}>
          <MotionHeading
            textAlign="center"
            mb={16}
            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
            fontWeight="bold"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Revolutionizing Your Dating Experience
          </MotionHeading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={12}>
            <FeatureCard
              icon={faSearch}
              title="AI-Powered Screening"
              description="Our advanced AI analyzes potential matches, identifying red flags and assessing compatibility to ensure safer dating experiences."
            />
            <FeatureCard
              icon={faUserShield}
              title="Community-Driven Insights"
              description="Access a wealth of verified reviews and ratings from fellow women, empowering you to make informed decisions about your dates."
            />
            <FeatureCard
              icon={faStar}
              title="Contribute & Empower"
              description="Share your dating experiences to help other women in the community, fostering a safer and more transparent dating environment."
            />
          </SimpleGrid>
        </Container>
      </Section>
    </Box>
  );
};
