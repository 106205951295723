import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { DropdownCategory, ReviewCategory } from "../types/review";

export const FLAG_CATEGORIES: Record<string, DropdownCategory> = {
  GREEN_FLAGS: {
    phrase: "Green Flags",
    prettyName: "green flags",
    options: [
      {
        prettyName: "Respectful",
        systemName: "respectful",
        emoji: "🙏",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Communicative",
        systemName: "good_communicator",
        emoji: "🗣️",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Punctual",
        systemName: "punctual",
        emoji: "🕰️",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Funny",
        systemName: "sense_of_humor",
        emoji: "🤣",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Kind",
        systemName: "kindness",
        emoji: "💖",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Confident",
        systemName: "confidence",
        emoji: "😎",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Shared Interests",
        systemName: "shared_interests",
        emoji: "🔗",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Thoughtful",
        systemName: "thoughtfulness",
        emoji: "💭",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Well-Groomed",
        systemName: "good_hygiene",
        emoji: "✨",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Ambitious",
        systemName: "ambition",
        emoji: "🏆",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Respects Boundaries",
        systemName: "respects_boundaries",
        emoji: "🚧",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Open-Minded",
        systemName: "open_mindedness",
        emoji: "🌈",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Fun to Be With",
        systemName: "fun_to_be_with",
        emoji: "🎭",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Stylish",
        systemName: "good_style",
        emoji: "💃",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Intelligent",
        systemName: "intelligence",
        emoji: "🧠",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Supportive",
        systemName: "supportiveness",
        emoji: "🌟",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Positive Attitude",
        systemName: "positive_attitude",
        emoji: "☀️",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Polite",
        systemName: "good_manners",
        emoji: "🎩",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Passionate",
        systemName: "passion",
        emoji: "💯",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Adventurous",
        systemName: "adventurousness",
        emoji: "🌎",
        color: "green.600",
        bg: "green.50",
      },
    ],
  },
  RED_FLAGS: {
    phrase: "Red Flags",
    prettyName: "red flags",
    options: [
      {
        prettyName: "Late, No Text",
        systemName: "late_no_notice",
        emoji: "🕰️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Rude to Staff",
        systemName: "rude_to_staff",
        emoji: "🤬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Self-Obsessed",
        systemName: "talks_only_self",
        emoji: "🗣️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Mentions Ex",
        systemName: "mentions_ex",
        emoji: "💔",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Always on Phone",
        systemName: "excessive_phone_use",
        emoji: "📱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Negative Vibes",
        systemName: "negative_attitude",
        emoji: "🌧️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Brags a Lot",
        systemName: "brags_excessively",
        emoji: "🦚",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Doesn't Listen",
        systemName: "doesnt_listen",
        emoji: "🙉",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Super Cheap",
        systemName: "super_cheap",
        emoji: "🪙",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Flirts Around",
        systemName: "flirts_with_others",
        emoji: "💋",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Social Media Addict",
        systemName: "social_media_obsessed",
        emoji: "📲",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Political Rants",
        systemName: "unwanted_political_rants",
        emoji: "🗯️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Bad Manners",
        systemName: "bad_manners",
        emoji: "🐷",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Ghosting Vibes",
        systemName: "potential_ghoster",
        emoji: "👻",
        color: "red.600",
        bg: "red.50",
      },
    ],
  },
  DEALBREAKERS: {
    phrase: "Dealbreakers",
    prettyName: "dealbreakers",
    options: [
      {
        prettyName: "Smoker",
        systemName: "smoking",
        emoji: "🚬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Different Religion",
        systemName: "different_religion",
        emoji: "🙏",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Excessive Drinking",
        systemName: "excessive_drinking",
        emoji: "🍺",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Party Animal",
        systemName: "excessive_partying",
        emoji: "🦄",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "No Sense of Humor",
        systemName: "no_sense_of_humor",
        emoji: "🧱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Different Goals",
        systemName: "different_life_goals",
        emoji: "🛣️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Oversharing",
        systemName: "over_sharing_too_soon",
        emoji: "📢",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "No Initiative",
        systemName: "lack_of_initiative",
        emoji: "🦥",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Offensive Language",
        systemName: "impolite_language",
        emoji: "🤬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Ghosting Vibes",
        systemName: "potential_ghoster",
        emoji: "👻",
        color: "red.600",
        bg: "red.50",
      },
    ],
  },
  TOPICS: {
    phrase: "Topics",
    prettyName: "topics",
    options: [
      {
        prettyName: "Technology",
        systemName: "technology",
        emoji: "💻",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Sports",
        systemName: "sports",
        emoji: "🏅",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Travel",
        systemName: "travel",
        emoji: "✈️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Food & Wine",
        systemName: "food_and_wine",
        emoji: "🍷",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Politics",
        systemName: "politics",
        emoji: "🗳️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Movies & TV",
        systemName: "movies_and_tv",
        emoji: "🎬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Music",
        systemName: "music",
        emoji: "🎵",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Fitness",
        systemName: "fitness",
        emoji: "🏋️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Hobbies",
        systemName: "hobbies",
        emoji: "🎨",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Career",
        systemName: "career",
        emoji: "💼",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Family",
        systemName: "family",
        emoji: "👨‍👩‍👧‍👦",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Art & Culture",
        systemName: "art_and_culture",
        emoji: "🖼️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Books",
        systemName: "books_and_literature",
        emoji: "📚",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Humor",
        systemName: "humor",
        emoji: "😂",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Personal Growth",
        systemName: "personal_growth",
        emoji: "🌱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Current Events",
        systemName: "current_events",
        emoji: "📰",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Environment",
        systemName: "environment",
        emoji: "🌍",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Philosophy",
        systemName: "philosophy",
        emoji: "🤔",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Pets",
        systemName: "pets",
        emoji: "🐾",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Dating",
        systemName: "dating_experiences",
        emoji: "💘",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Goals",
        systemName: "dreams_and_goals",
        emoji: "🎯",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Funny Stories",
        systemName: "funny_stories",
        emoji: "🤣",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Childhood",
        systemName: "childhood",
        emoji: "👶",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Volunteering",
        systemName: "volunteering",
        emoji: "🤲",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Entrepreneurship",
        systemName: "entrepreneurship",
        emoji: "🚀",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Gaming",
        systemName: "gaming",
        emoji: "🎮",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Fashion",
        systemName: "fashion",
        emoji: "👗",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Science",
        systemName: "science",
        emoji: "🔬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Spirituality",
        systemName: "spirituality",
        emoji: "🕉️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Relationships",
        systemName: "relationships",
        emoji: "❤️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Fun Facts",
        systemName: "fun_facts",
        emoji: "💡",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Memes",
        systemName: "memes",
        emoji: "🐸",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Cars",
        systemName: "cars_and_motorsports",
        emoji: "🏎️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Outdoors",
        systemName: "outdoor_activities",
        emoji: "🏞️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "History",
        systemName: "history",
        emoji: "📜",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Languages",
        systemName: "language_learning",
        emoji: "🗣️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Cooking",
        systemName: "culinary_skills",
        emoji: "👨‍🍳",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Finance",
        systemName: "financial_markets",
        emoji: "📈",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Psychology",
        systemName: "psychology",
        emoji: "🧠",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Comedy",
        systemName: "stand_up_comedy",
        emoji: "🎤",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Astronomy",
        systemName: "astronomy",
        emoji: "🔭",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Culture",
        systemName: "cultural_experiences",
        emoji: "🌎",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Social Issues",
        systemName: "social_issues",
        emoji: "🤝",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Mythology",
        systemName: "mythology",
        emoji: "🧙",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Photography",
        systemName: "photography",
        emoji: "📷",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "DIY",
        systemName: "DIY_projects",
        emoji: "🔨",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Meditation",
        systemName: "meditation",
        emoji: "🧘",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Comics",
        systemName: "comics_and_superheroes",
        emoji: "🦸",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Anime",
        systemName: "anime_and_manga",
        emoji: "🇯🇵",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Collecting",
        systemName: "collecting",
        emoji: "🏛️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Pet Peeves",
        systemName: "pet_peeves",
        emoji: "😤",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Life Hacks",
        systemName: "life_hacks",
        emoji: "🔧",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Life Moments",
        systemName: "standout_moments",
        emoji: "🌟",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Holidays",
        systemName: "holiday_plans",
        emoji: "🏖️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Future Tech",
        systemName: "future_tech",
        emoji: "🤖",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Health",
        systemName: "health_and_nutrition",
        emoji: "🥗",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "City Life",
        systemName: "urban_exploration",
        emoji: "🏙️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Theater",
        systemName: "theater_and_performing_arts",
        emoji: "🎭",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Beer",
        systemName: "beer_and_brewing",
        emoji: "🍺",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Wine",
        systemName: "wine_tasting",
        emoji: "🍇",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Social Media",
        systemName: "social_media_trends",
        emoji: "📱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Crypto",
        systemName: "crypto_and_blockchain",
        emoji: "💰",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Home Decor",
        systemName: "home_decor",
        emoji: "🏠",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Sustainability",
        systemName: "sustainability",
        emoji: "♻️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Alt Music",
        systemName: "alternative_music",
        emoji: "🎸",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Festivals",
        systemName: "festivals_and_events",
        emoji: "🎉",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Podcasts",
        systemName: "podcasts",
        emoji: "🎧",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Water Sports",
        systemName: "standup_paddleboarding",
        emoji: "🏄",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Martial Arts",
        systemName: "martial_arts",
        emoji: "🥋",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Board Games",
        systemName: "board_games",
        emoji: "🧩",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Genealogy",
        systemName: "genealogy",
        emoji: "👪",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "VR",
        systemName: "virtual_reality",
        emoji: "👓",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Improv",
        systemName: "improv_comedy",
        emoji: "🎭",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "E-sports",
        systemName: "e_sports",
        emoji: "🏆",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "NYC Life",
        systemName: "life_in_nyc",
        emoji: "🗽",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Weekend Plans",
        systemName: "upcoming_plans",
        emoji: "📅",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Weather",
        systemName: "weather_small_talk",
        emoji: "🌤️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Celeb Gossip",
        systemName: "celebrity_gossip",
        emoji: "🌟",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Anecdotes",
        systemName: "personal_anecdotes",
        emoji: "🗣️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Unique Skills",
        systemName: "unusual_skills",
        emoji: "🎪",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Adventure",
        systemName: "adventure_sports",
        emoji: "🧗",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Urban Legends",
        systemName: "urban_legends",
        emoji: "👻",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Cooking",
        systemName: "cooking",
        emoji: "🍳",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Relationships",
        systemName: "relationships",
        emoji: "💑",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Childhood Dreams",
        systemName: "childhood_dreams",
        emoji: "💭",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Success Stories",
        systemName: "success_stories",
        emoji: "🏅",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Life Lessons",
        systemName: "failures_and_lessons",
        emoji: "📚",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Philosophy",
        systemName: "life_philosophy",
        emoji: "🧘",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Quotes",
        systemName: "favorite_quotes",
        emoji: "💬",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Bucket List",
        systemName: "bucket_list",
        emoji: "📝",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Daydreams",
        systemName: "daydreams",
        emoji: "💭",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Sci-Fi",
        systemName: "sci_fi_and_fantasy",
        emoji: "🚀",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Pandemic Life",
        systemName: "life_in_pandemic",
        emoji: "😷",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Minimalism",
        systemName: "minimalism",
        emoji: "🧘",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Tech Ethics",
        systemName: "technology_ethics",
        emoji: "🤔",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Apps",
        systemName: "favorite_apps",
        emoji: "📱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Coffee",
        systemName: "coffee",
        emoji: "☕",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Supernatural",
        systemName: "supernatural",
        emoji: "👻",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Aliens",
        systemName: "alien_life",
        emoji: "👽",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "True Crime",
        systemName: "true_crime",
        emoji: "🕵️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Gardening",
        systemName: "gardening",
        emoji: "🌱",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Memes",
        systemName: "funny_meme_sharing",
        emoji: "😂",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Languages",
        systemName: "languages",
        emoji: "🗣️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Work-Life Balance",
        systemName: "work_life_balance",
        emoji: "⚖️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Mentorship",
        systemName: "mentorship",
        emoji: "🧑‍🏫",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Astrology",
        systemName: "astrology",
        emoji: "♈",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Board Sports",
        systemName: "board_sports",
        emoji: "🛹",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Team Sports",
        systemName: "team_sports",
        emoji: "⚽",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Diet",
        systemName: "diet_and_lifestyle",
        emoji: "🥑",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Self-Care",
        systemName: "self_care",
        emoji: "🧖",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Open Source",
        systemName: "open_source",
        emoji: "🖥️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Startups",
        systemName: "startup_scene",
        emoji: "🏙️",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Networking",
        systemName: "networking",
        emoji: "🤝",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Jokes",
        systemName: "favorite_jokes",
        emoji: "😆",
        color: "red.600",
        bg: "red.50",
      },
    ],
  },
};

export const TILE_CATEGORIES: Record<string, DropdownCategory> = {
  MEET_LOCATION: {
    phrase: "Where did you meet?",
    prettyName: "meeting",
    options: [
      {
        prettyName: "tinder",
        systemName: "tinder",
        imagePath: "/tinder.png",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "bumble",
        systemName: "bumble",
        imagePath: "/bumble.png",
        color: "yellow.700",
        bg: "yellow.50",
      },
      {
        prettyName: "hinge",
        systemName: "hinge",
        imagePath: "/hinge.png",
        color: "gray.600",
        bg: "gray.50",
      },
      {
        prettyName: "in person",
        systemName: "social event",
        icon: faRunning,
        color: "blue.600",
        bg: "blue.50",
      },
      {
        prettyName: "other",
        systemName: "other",
        emoji: "🤔 ",
        color: "purple.600",
        bg: "purple.50",
      },
    ],
  },
  DATE_TYPE: {
    phrase: "Go-To Date",
    prettyName: "date type",
    options: [
      {
        prettyName: "Drinks",
        systemName: "drinks",
        emoji: "🍸",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Dinner",
        systemName: "dinner",
        emoji: "🍽️",
        color: "orange.600",
        bg: "orange.50",
      },
      {
        prettyName: "Movie",
        systemName: "movie",
        emoji: "🎬",
        color: "yellow.600",
        bg: "yellow.50",
      },
      {
        prettyName: "Concert",
        systemName: "concert",
        emoji: "🎵",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Bar",
        systemName: "bar",
        emoji: "🍺",
        color: "teal.600",
        bg: "teal.50",
      },
      {
        prettyName: "Coffee",
        systemName: "coffee",
        emoji: "☕",
        color: "blue.600",
        bg: "blue.50",
      },
      {
        prettyName: "Museum",
        systemName: "museum",
        emoji: "🏛️",
        color: "cyan.600",
        bg: "cyan.50",
      },
      {
        prettyName: "Park",
        systemName: "park",
        emoji: "🌳",
        color: "purple.600",
        bg: "purple.50",
      },
      {
        prettyName: "Beach",
        systemName: "beach",
        emoji: "🏖️",
        color: "pink.600",
        bg: "pink.50",
      },
      {
        prettyName: "Hike",
        systemName: "hike",
        emoji: "🥾",
        color: "lime.600",
        bg: "lime.50",
      },
      {
        prettyName: "Art",
        systemName: "art",
        emoji: "🎨",
        color: "gray.600",
        bg: "gray.50",
      },
      {
        prettyName: "Music",
        systemName: "music",
        emoji: "🎶",
        color: "orange.600",
        bg: "orange.50",
      },
      {
        prettyName: "Dance",
        systemName: "dance",
        emoji: "💃",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Theater",
        systemName: "theater",
        emoji: "🎭",
        color: "purple.600",
        bg: "purple.50",
      },
      {
        prettyName: "Comedy",
        systemName: "comedy",
        emoji: "🎤",
        color: "yellow.600",
        bg: "yellow.50",
      },
      {
        prettyName: "Club",
        systemName: "club",
        emoji: "🕺",
        color: "blue.600",
        bg: "blue.50",
      },
    ],
  },
  STAR_SIGN: {
    phrase: "Star Sign",
    prettyName: "star sign",
    options: [
      {
        prettyName: "Aries",
        systemName: "aries",
        emoji: "♈",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Taurus",
        systemName: "taurus",
        emoji: "♉",
        color: "green.600",
        bg: "green.50",
      },
      {
        prettyName: "Gemini",
        systemName: "gemini",
        emoji: "♊",
        color: "yellow.600",
        bg: "yellow.50",
      },
      {
        prettyName: "Cancer",
        systemName: "cancer",
        emoji: "♋",
        color: "blue.600",
        bg: "blue.50",
      },
      {
        prettyName: "Leo",
        systemName: "leo",
        emoji: "♌",
        color: "orange.600",
        bg: "orange.50",
      },
      {
        prettyName: "Virgo",
        systemName: "virgo",
        emoji: "♍",
        color: "purple.600",
        bg: "purple.50",
      },
      {
        prettyName: "Libra",
        systemName: "libra",
        emoji: "♎",
        color: "pink.600",
        bg: "pink.50",
      },
      {
        prettyName: "Scorpio",
        systemName: "scorpio",
        emoji: "♏",
        color: "red.600",
        bg: "red.50",
      },
      {
        prettyName: "Sagittarius",
        systemName: "sagittarius",
        emoji: "♐",
        color: "purple.600",
        bg: "purple.50",
      },
      {
        prettyName: "Capricorn",
        systemName: "capricorn",
        emoji: "♑",
        color: "gray.600",
        bg: "gray.50",
      },
      {
        prettyName: "Aquarius",
        systemName: "aquarius",
        emoji: "♒",
        color: "blue.600",
        bg: "blue.50",
      },
      {
        prettyName: "Pisces",
        systemName: "pisces",
        emoji: "♓",
        color: "teal.600",
        bg: "teal.50",
      },
    ],
  },
};

export const REVIEW_CATEGORIES: Record<string, ReviewCategory> = {
  safety: {
    prettyName: "safety",
    options: [
      {
        stars: 1,
        title: "Change Your Number",
        description:
          "The kind of vibe that makes pepper spray feel like a good accessory choice.",
      },
      {
        stars: 2,
        title: "Cringe or Danger?",
        description:
          "Not exactly dangerous, but you'd rather he didn't know where you live. Just awkward enough to make you keep your guard up.",
      },
      {
        stars: 3,
        title: "Seemingly Harmless",
        description:
          "Didn't set off any alarms, but also didn't put in much effort. You're fine, but you don't feel taken care of.",
      },
      {
        stars: 4,
        title: "No Worries Here",
        description:
          "Confident without crossing the line, with just the right amount of mystery. He's safe enough for a second round.",
      },
      {
        stars: 5,
        title: "A Safety Unicorn",
        description:
          "Practically a unicorn in the dating world: respectful, relaxed, and makes you feel completely at ease. Date gold.",
      },
    ],
  },
  humor: {
    prettyName: "humor",
    options: [
      {
        stars: 1,
        title: "Crickets",
        description:
          "His jokes landed like a lead balloon. You laughed—out of politeness.",
      },
      {
        stars: 2,
        title: "Dad Jokes",
        description:
          "Somewhere between mildly amusing and 'please stop.' Feels like watching a comedy show without the comedy.",
      },
      {
        stars: 3,
        title: "Mildly Amusing",
        description:
          "Some chuckles here and there, but nothing that would make you choke on your drink. Think more 'charming chuckle' than 'belly laugh.'",
      },
      {
        stars: 4,
        title: "Sharp and Witty",
        description:
          "Quick on his feet and knows how to keep the conversation lively and interesting. You'd probably save his best line for the group chat.",
      },
      {
        stars: 5,
        title: "Sign Him Up for Netflix",
        description:
          "This guy's humor could sell out a show. Tears-in-your-eyes, can't-catch-your-breath funny. Date him just for the entertainment.",
      },
    ],
  },
  bedroom: {
    prettyName: "bedroom",
    options: [
      {
        stars: 1,
        title: "Did He Even Show Up?",
        description:
          "More snooze than sizzle. Left you staring at the ceiling and reconsidering your life choices.",
      },
      {
        stars: 2,
        title: "Participation Trophy",
        description:
          "Some effort was made, but it felt more like a group project where only one person did the work—and it wasn't him.",
      },
      {
        stars: 3,
        title: "Gets the Job Done",
        description:
          "Like ordering vanilla when you wanted rocky road. It works, but you're not bragging about it.",
      },
      {
        stars: 4,
        title: "Encore",
        description:
          "Knows his moves and keeps things interesting. Almost made you forget there's a world outside the sheets.",
      },
      {
        stars: 5,
        title: "Mind-Blowing",
        description:
          "Left you breathless and questioning reality. No need for lower east bars—this one's worth staying in for.",
      },
    ],
  },
  "cheap-expensive": {
    prettyName: "bougieness",
    options: [
      {
        stars: 1,
        title: "Subway and Happy Hour",
        description:
          "No Uber for you, just a walk from the subway and a seat at the dive bar. Drinks? Sure—if they're half-price. He suggests splitting the check after you shared fries.",
      },
      {
        stars: 2,
        title: "'Let's Split It'",
        description:
          'Takes you for coffee in the West Village but winces at the avocado toast prices. Offers to pay, but only after you insist on going halves. The kind of guy who\'s got a studio walk-up with a "cozy" Murphy bed.',
      },
      {
        stars: 3,
        title: "Midtown Meal",
        description:
          "He picks a decent spot—think mid-tier Italian in Hell's Kitchen. He covers dinner but hails a shared Uber home. His place? Nice enough, but it screams 'IKEA starter pack.'",
      },
      {
        stars: 4,
        title: "Soho Style",
        description:
          "Reservations at that trendy Soho spot and doesn't bat an eye at the bill. Offers to get you an Uber back, too. His apartment is well-decorated, and his fridge has more than just leftover takeout.",
      },
      {
        stars: 5,
        title: "First-Class Fling",
        description:
          "Black car to a hot new Michelin star restaurant, no checks discussed. Offers you a nightcap in a high-rise with a skyline view and a fully stocked bar. This one's playing in the big leagues.",
      },
    ],
  },
  catfish: {
    prettyName: "catfish?",
    options: [
      {
        stars: 1,
        title: "Photos Lie",
        description:
          "That profile pic must've been from a different decade—or dimension. In real life, he's the \"before\" in a makeover show.",
      },
      {
        stars: 2,
        title: "Angles and Filters",
        description:
          "He looked good… online. In person? Let's just say his photos deserve an award for fiction.",
      },
      {
        stars: 3,
        title: "As Advertised",
        description:
          "No big surprises—he is who he said he was, but don't expect him to wow you more than his profile did.",
      },
      {
        stars: 4,
        title: "Pleasantly Surprised",
        description:
          "He's like a great indie movie—way better than the trailer. More charisma, more presence, definitely a good twist.",
      },
      {
        stars: 5,
        title: "Even Hotter in 3D",
        description:
          "Forget the profile; this guy's a total upgrade in person. Looks, charm, and the whole package—this is a cinematic experience.",
      },
    ],
  },
  kinkiness: {
    prettyName: "kinkiness",
    options: [
      {
        stars: 1,
        title: "Old Fashioned",
        description:
          "Straightforward and gentle—think soft touches and slow rhythms. Not much beyond the basics, but comforting if you prefer to keep things simple.",
      },
      {
        stars: 2,
        title: "Gin and Tonic",
        description:
          "Shows a bit of boldness with some light teasing and playful grabs. Open to stepping out of his comfort zone, but only just a step.",
      },
      {
        stars: 3,
        title: "Paloma",
        description:
          "Flirts with a few surprises—maybe a blindfold or a little power play. Keeps things mostly balanced, but enjoys spicing it up just enough.",
      },
      {
        stars: 4,
        title: "Spicy Margarita",
        description:
          "A mix of sweet and daring. Comfortable with adding intensity—think hands tied or a firm grip, and knows how to keep things electrifying without losing control.",
      },
      {
        stars: 5,
        title: "Absinthe",
        description:
          "No reservations—ready for intricate games, exploring limits, and diving into deep fantasies. Always up for a night that's anything but ordinary.",
      },
    ],
  },
};
