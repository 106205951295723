import { useState } from "react";
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { ReviewStarOptions } from "../review/ReviewStarOptions";
import { REVIEW_CATEGORIES } from "../../data/review";
import { useReviewRef } from "../../providers/use-review";

export function Sex() {
  const [tabIndex, setTabIndex] = useState(0);

  const { reviewData, setReviewData } = useReviewRef();

  const handleBedroomChange = (rating: number) => {
    setReviewData({
      ...reviewData,
      ratings: {
        ...reviewData.ratings,
        bedroom: rating,
      },
    });
  };

  const handleKinkChange = (rating: number) => {
    setReviewData({
      ...reviewData,
      ratings: {
        ...reviewData.ratings,
        kinkiness: rating,
      },
    });
  };

  return (
    <Flex flexDirection="column" flex={1} height={"100%"}>
      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
        variant="soft-rounded"
        colorScheme="pink"
        size="sm"
        mt={1}
        height={"100%"}
      >
        <TabList justifyContent="center">
          <Tab mx={1} py={1} px={3}>
            Bedroom
          </Tab>
          <Tab mx={1} py={1} px={3}>
            Kink
          </Tab>
        </TabList>
        <TabPanels height={"90%"} overflowY={"scroll"}>
          <TabPanel py={0} paddingBottom={1} px={0}>
            <ReviewStarOptions
              category={REVIEW_CATEGORIES.bedroom}
              onChange={handleBedroomChange}
              showTitle={false}
              selected={reviewData.ratings.bedroom}
            />
          </TabPanel>
          <TabPanel py={0} paddingBottom={1} px={0}>
            <ReviewStarOptions
              category={REVIEW_CATEGORIES.kinkiness}
              onChange={handleKinkChange}
              showTitle={false}
              selected={reviewData.ratings.kinkiness}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
