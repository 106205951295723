import { Flex } from "@chakra-ui/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function SearchButton({ onClick }: { onClick: () => void }) {
  return (
    <Flex
      as="button"
      position="fixed"
      bottom="4"
      right="4"
      width="60px"
      height="60px"
      borderRadius="full"
      bg="pink.400"
      color="white"
      boxShadow="0 4px 10px rgba(0, 0, 0, 0.2)"
      justifyContent="center"
      alignItems="center"
      transition="all 0.3s ease"
      _hover={{
        bg: "pink.500",
        transform: "translateY(-2px)",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
      }}
      _active={{
        bg: "pink.600",
        transform: "translateY(1px)",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
      }}
      onClick={onClick}
      aria-label="Search new date"
    >
      <FontAwesomeIcon icon={faSearch} size="lg" />
    </Flex>
  );
}
